import React, { useState, useEffect, useRef, useContext } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import {
  AdminForm,
  CashierForm,
  CategoryButton,
  DoctorForm,
  InvestorForm,
  LoadingPopUp,
  ManagerForm,
  SubmitPopUp,
  SupportForm,
  ToastNotification,
} from "../../../components";
import HeadersTitle from "./table-blocks/HeadersTitle";
import UserItem from "./table-blocks/UserItem";
import HeadersTitleFacilities from "./table-blocks/HeadersTitleFacilities";
import FacilityItem from "./table-blocks/FacilityItem";
import FacilityPopUp from "./facility-pop-up/FacilityPopUp";
import CreateFacility from "./CreateFacility";
import SelectOwnerPopUp from "./SelectOwnerPopUp";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
  TextInputFieldSearch,
} from "../../../components/atoms";
import { AddFacilityPopUp, Filter } from "./facility-components";
import { AdminContext } from "../../../context/admin-context";
import { useNavigate } from "react-router-dom";
import RegionDropDown from "./region-drop-down/RegionDropDown";
import {
  InviteHeader,
  InviteListItem,
  InvitePractioner,
  PractionerHeader,
  PractionerListItem,
} from "./practioners-components";
import FilterPractioner from "./practioners-components/filter-practioner/FilterPractioner";

const Facilities = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
  width,
  height,
  display,
}) => {
  const admin = useContext(AdminContext);
  const [facilitiesArray, setFacilitiesArray] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [completedPopUp, setCompletePopUp] = useState(false);
  const [ownerArray, setOwnerArrray] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("error");

  const [landLine, setLandLine] = useState("");
  const [type, setType] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [province, setProvince] = useState("none");
  const [storeName, setStoreName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressName, setStreetAddressName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");

  const [para1, setPara1] = useState("none");
  const [para2, setPara2] = useState("nothing");

  const [locationLink, setLoactionLink] = useState("");

  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThurday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");
  const [publicTimes, setPublicTimes] = useState("");

  const [remunirationPercentage, setRemunirationPercentage] = useState("");

  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");

  const [imageLink, setImageLink] = useState("");
  // selected item display
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [openFacilityDisplay, setOpenFacilityDisplay] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [addFacilityPopUp, setAddFacilityPopUp] = useState(false);

  const [search, setSearch] = useState("");

  const [mondayTo, setMondayTo] = useState("");
  const [tuesdayTo, setTuesdayTo] = useState("");
  const [wedTo, setWedTo] = useState("");
  const [thurTo, setThurTo] = useState("");
  const [friTo, setFriTo] = useState("");
  const [satTo, setSatTo] = useState("");
  const [sunTo, setSunTo] = useState("");
  const [pubTo, setPubTo] = useState("");

  const [ownerSlectedObject, setOwnerSlectedObject] = useState("");
  // console.log("check facilty", selectedFacility);

  const [screen, setScreen] = useState("stores");

  const [statusValue, setStatusValue] = useState("");

  const [refresh, setRefresh] = useState(false);

  const [invitesArray, setInvitesArray] = useState(null);

  const [practionersArray, setPractionersArray] = useState(null);
  const [practionerManage, setPractionerManage] = useState(false);
  const [selectedPrcatitionerManage, setSelectedPractitionerManage] =
    useState(null);
  const [pendingPractitionerSelected, setPendingPractitionerSelected] =
    useState(null);
  const [practionerInviteManage, setPractionerInviteManage] = useState(false);

  const [approvePractitionerData1, setApprovePractitioner] = useState(false);
  const [approvePractitionerData2, setApprovePractitione2] = useState(false);

  // practioner variables
  const [invitePractionerPopUp, setInvitePractionerPopUp] = useState(false);

  const [practionerFirtName, setPractionerFistName] = useState("");
  const [practionerLastName, setPractionerLastName] = useState("");
  const [practionerEmail, setPractionerEmail] = useState("");
  const [practionerId, setPractionerId] = useState("");
  const [practionerCellNumber, setPractionerCellNumber] = useState("");
  const [practitionerDateOfBirth, setPractitionerDateOfBirth] = useState("");
  const [userName, setUserName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [practititonerProvince, setPractititonerProvince] = useState("");
  const [practititonerType, setPractititonerType] = useState("");
  const [practitionerStatus, setPractitionerStatus] = useState("");
  const [practionerPin, setPractitionerPin] = useState("");

  const ResetFields = () => {
    setStoreName("");
    setStreetAddress("");
    setSuburb("");
    setCity("");
    setPostCode("");
    setProvince("");
    setLandLine("");
    setCellNumber("");
    setEmail("");
    setType("");
    setPara1("");
    setPara2("");
  };

  const AddNewFacility = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/facilities/createStore",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: storeName,
            locationLink: locationLink,
            streetAddress: streetAddressName,
            suburb: suburb,
            city: city,
            postalCode: postCode,
            province: province,
            landLine: landLine,
            cellNumber: cellNumber,
            email: email,
            type: type,
            monday: monday + " - " + mondayTo,
            tuesday: tuesday + " - " + tuesdayTo,
            wednesday: wednesday + " - " + wedTo,
            thursday: thursday + " - " + thurTo,
            friday: friday + " - " + friTo,
            saturday: saturday + " - " + satTo,
            sunday: sunday + " - " + sunTo,
            publicHoliday: publicTimes + " - " + pubTo,
            createdDate: new Date().toDateString(),
            lastEdited: new Date().toDateString(),
            editedBy:
              admin.adminUserData.firstName +
              " " +
              admin.adminUserData.lastName,
            descriptionP1: para1,
            descriptionp2: para2,
            image: imageLink,
            ownerId: ownerSlectedObject.id,
            ownerName:
              ownerSlectedObject.firstName + " " + ownerSlectedObject.lastName,
            remunirationPercentage: remunirationPercentage,
            status: statusValue,
            unitNumber: streetAddress,
          }),
        }
      );
      const respnseData = await response.json();
      if (respnseData.store) {
        const wait = await updateListFetch();
        setSelectedOwner("");
        setCompletePopUp(false);
        setLoading(false);
        setToastPop(true);
        setSubSelection("All FACILITES");
        setMessage(
          "A new Facility has been added to the database, the owner will get a email"
        );
        setAddFacilityPopUp(false);
        ResetFields();
      } else {
        setToastPop(true);
        setMessage("Unable to create facilty" + " ," + respnseData.message);
        setError("invalid credentials");
        setLoading(false);
        console.log("error data is missing");
      }
    } catch (err) {
      console.log("test", err);
    }
  };

  // console.log("pendingPractitionerSelected", pendingPractitionerSelected);

  const updateListFetch = async () => {
    await fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setFacilitiesArray(resData);
      });
  };

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setFacilitiesArray(resData);
      });
    fetch("https://merry-jane-api.onrender.com/cannaPos/fetchOwners")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setOwnerArrray(resData);
      });
  }, []);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  useEffect(() => {
    if (subSelection === "PRACTIONERS") {
      setScreen("practioners");
    } else {
      setScreen("stores");
    }
  }, [subSelection]);
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/practioner/getAllInvites")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setInvitesArray(resData);
      });
    fetch("https://merry-jane-api.onrender.com/cannaPos/fetchAllUsers")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setPractionersArray(resData);
      });
  }, [refresh]);
  useEffect(() => {
    if (pendingPractitionerSelected) {
      // here is where the approval fileds will be added for review
      setPractionerFistName(pendingPractitionerSelected.firstName);
      setPractionerLastName(pendingPractitionerSelected.lastName);
      setPractionerEmail(pendingPractitionerSelected.email);
      setPractionerId(pendingPractitionerSelected.idDoc);
      setPractionerCellNumber(pendingPractitionerSelected.cellNumber);
      setPractitionerDateOfBirth(pendingPractitionerSelected.dateOfBirth);
      setAccountNumber(pendingPractitionerSelected.accountNumber);
      setUserName(pendingPractitionerSelected.userName);
      setPractititonerProvince(pendingPractitionerSelected.province);
      setPractititonerType(pendingPractitionerSelected.type);
      setPractitionerStatus(pendingPractitionerSelected.status);
      setPractitionerPin(pendingPractitionerSelected.pin);
    }
  }, [pendingPractitionerSelected]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {subSelection === "All FACILITES" || subSelection === "none" ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            // borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div
            style={{
              height: 60,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 10, marginRight: 10, width: 220 }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 150,
                  width: 300,

                  borderWidth: 0.5,
                }}
              >
                <TextInputFieldSearch
                  placeholder={"Type here to search"}
                  height={25}
                  borderRadius={4}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <div
                  style={{
                    height: 30,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => setSearch("")}
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    CLEAR
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Filter filterName={"FILTER"} setScreen={setScreen} />
            </div>
            <BaseButtons
              label={"+ ADD FACILITY"}
              mini={true}
              marginTop={-2}
              // marginTopFalse={true}
              height={35}
              fontSize={12}
              borderRadius={20}
              onClick={() => setAddFacilityPopUp(true)}
            />
            <div style={{ width: 10 }} />
          </div>
          <HeadersTitleFacilities
          // marginTop={10}
          />
          {screen === "stores" ? (
            <div style={{ flex: 1, overflow: "scroll", width: "100%" }}>
              {facilitiesArray.store
                ? facilitiesArray.store.map((store, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FacilityItem
                          onManageClick={() => {
                            setSelectedFacility(store);
                            setOpenFacilityDisplay(true);
                          }}
                          onDeleteClick={() => {
                            setSelectedFacility(store);
                            setOpenFacilityDisplay(true);
                            setIsDeleting(true);
                          }}
                          storeName={store.storeName}
                          province={store.province}
                          city={store.city}
                          type={store.type}
                          email={store.email}
                          cell={store.cellNumber}
                          owner={"Eaxmple name here"}
                        />
                      </div>
                    );
                  })
                : null}
              <div style={{ padding: 40 }} />
            </div>
          ) : null}
          {screen === "regions" ? (
            <div style={{ flex: 1, overflow: "scroll", width: "100%" }}>
              <RegionDropDown
                title={"Western Cape"}
                data={
                  facilitiesArray.westernCape ? facilitiesArray.westernCape : []
                }
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              <RegionDropDown
                title={"Gauteng"}
                data={facilitiesArray.gauteng ? facilitiesArray.gauteng : []}
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              <RegionDropDown
                title={"Eastern Cape"}
                data={
                  facilitiesArray.easternCape ? facilitiesArray.easternCape : []
                }
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              <RegionDropDown
                title={"Free State"}
                data={
                  facilitiesArray.freeState ? facilitiesArray.freeState : []
                }
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              <RegionDropDown
                title={"Kwa-zulu Natal"}
                data={
                  facilitiesArray.kwazuluNatal
                    ? facilitiesArray.kwazuluNatal
                    : []
                }
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              <RegionDropDown
                title={"Limpopo"}
                data={facilitiesArray.limpopo ? facilitiesArray.limpopo : []}
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              <RegionDropDown
                title={"Mpumalanga"}
                data={
                  facilitiesArray.mpumalanga ? facilitiesArray.mpumalanga : []
                }
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              <RegionDropDown
                title={"North West"}
                data={
                  facilitiesArray.northWest ? facilitiesArray.northWest : []
                }
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              <RegionDropDown
                title={"Northern Cape"}
                data={
                  facilitiesArray.northernCape
                    ? facilitiesArray.northernCape
                    : []
                }
                setOpenFacilityDisplay={setOpenFacilityDisplay}
                setSelectedFacility={setSelectedFacility}
              />
              {/* {facilitiesArray.westernCape
                ? facilitiesArray.westernCape.map((store, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FacilityItem
                          onManageClick={() => {
                            setSelectedFacility(store);
                            setOpenFacilityDisplay(true);
                          }}
                          onDeleteClick={() => {
                            setSelectedFacility(store);
                            setOpenFacilityDisplay(true);
                            setIsDeleting(true);
                          }}
                          storeName={store.storeName}
                          province={store.province}
                          city={store.city}
                          type={store.type}
                          email={store.email}
                          cell={store.cellNumber}
                          owner={"Eaxmple name here"}
                        />
                      </div>
                    );
                  })
                : null} */}
              <div style={{ padding: 40 }} />
            </div>
          ) : null}
        </div>
      ) : null}
      {subSelection === "PRACTIONERS" ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            // borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div
            style={{
              height: 60,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 10, marginRight: 10, width: 220 }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 150,
                  width: 300,

                  borderWidth: 0.5,
                }}
              >
                <TextInputFieldSearch
                  placeholder={"Type here to search"}
                  height={25}
                  borderRadius={4}
                />
                <div
                  style={{
                    height: 30,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => setSearch("")}
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    CLEAR
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <FilterPractioner filterName={"FILTER"} setScreen={setScreen} />
            </div>
            <BaseButtons
              label={"+ INVITE PRACTIONER"}
              mini={true}
              marginTop={-2}
              // marginTopFalse={true}
              height={35}
              fontSize={12}
              borderRadius={20}
              size={170}
              onClick={() => setInvitePractionerPopUp(true)}
            />
            <div style={{ width: 10 }} />
          </div>
          {screen === "practioners" ? <PractionerHeader /> : null}
          {screen === "practioners"
            ? practionersArray
              ? practionersArray.data.map((practitioner, index) => {
                  return practitioner.type === "Practitioner" ? (
                    <div key={index} style={{ width: "100%" }}>
                      <PractionerListItem
                        type={practitioner.type}
                        firstName={practitioner.firstName}
                        lastName={practitioner.lastName}
                        email={practitioner.email}
                        contactNumber={practitioner.cellNumber}
                        status={practitioner.status}
                        onManageClick={() => {
                          setSelectedPractitionerManage(practitioner);
                          setPractionerManage(true);
                        }}
                      />
                    </div>
                  ) : null;
                })
              : null
            : null}
          {screen === "invites" ? <InviteHeader /> : null}
          {screen === "invites"
            ? invitesArray
              ? invitesArray.invitation.map((invite, index) => {
                  return (
                    <div key={index} style={{ width: "100%" }}>
                      <InviteListItem
                        index={index}
                        firstName={invite.firstName}
                        lastName={invite.lastName}
                        email={invite.email}
                        contactNumber={invite.cellNumber}
                        code={invite.code}
                      />
                    </div>
                  );
                })
              : null
            : null}
          {screen === "pending" ? <PractionerHeader /> : null}
          {screen === "pending"
            ? practionersArray
              ? practionersArray.data.map((practitioner, index) => {
                  return practitioner.type === "Practitioner" &&
                    practitioner.status === "Pending" ? (
                    <div key={index} style={{ width: "100%" }}>
                      <PractionerListItem
                        type={practitioner.type}
                        firstName={practitioner.firstName}
                        lastName={practitioner.lastName}
                        email={practitioner.email}
                        contactNumber={practitioner.cellNumber}
                        status={practitioner.status}
                        onManageClick={() => {
                          setPendingPractitionerSelected(practitioner);
                          setPractionerInviteManage(true);
                        }}
                      />
                    </div>
                  ) : null;
                })
              : null
            : null}
          {/* manage practitinoer apprval */}
          <div
            style={{
              height: practionerInviteManage ? "100%" : 0,
              width: "100%",
              overflow: "hidden",
              position: "absolute",
              backgroundColor: BlackAndWhite.secondary,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              transition: "all 0.3s",
              zIndex: 400,
            }}
          >
            <div style={{ position: "absolute", top: 10, left: 10 }}>
              <CloseButton
                label={"X"}
                onClick={() => {
                  setPractionerInviteManage(false);
                  setPendingPractitionerSelected(null);
                }}
              />
            </div>
            <div
              style={{
                height: 55,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: 65, fontSize: 18, fontWeight: "bold" }}>
                APPROVE PRACTIONER
              </div>
              <div style={{ flex: 1 }}></div>
              <div style={{ display: "flex", marginRight: 10 }}>
                <BaseButtons
                  label={"APPROVE"}
                  mini={true}
                  marginTopFalse={false}
                  marginTop={10}
                  height={40}
                  borderRadius={8}
                  disable={
                    approvePractitionerData1 && approvePractitionerData2
                      ? false
                      : true
                  }
                />
                <div style={{ width: 10 }} />
                <BaseButtons
                  label={"DECLINE"}
                  mini={true}
                  marginTopFalse={false}
                  marginTop={10}
                  height={40}
                  borderRadius={8}
                />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                position: "relative",
                width: "100%",
              }}
            >
              {/* base core information left side */}
              <div
                style={{
                  flex: 0.5,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    readOnly={true}
                    mini={true}
                    invert={true}
                    labelTitle={"First Name"}
                    value={practionerFirtName}
                    width={"50%"}
                    size={"70%"}
                  />
                  <TextInputField
                    readOnly={true}
                    mini={true}
                    invert={true}
                    labelTitle={"Last Name"}
                    value={practionerLastName}
                    width={"50%"}
                    size={"70%"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    readOnly={true}
                    mini={true}
                    invert={true}
                    labelTitle={"Contact Number"}
                    value={practionerCellNumber}
                    width={"50%"}
                    size={"70%"}
                  />
                  <TextInputField
                    readOnly={true}
                    mini={true}
                    invert={true}
                    labelTitle={"Email"}
                    value={practionerEmail}
                    width={"50%"}
                    size={"70%"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    readOnly={true}
                    mini={true}
                    invert={true}
                    labelTitle={"ID Number"}
                    value={practionerId}
                    width={"50%"}
                    size={"70%"}
                  />
                  <TextInputField
                    readOnly={true}
                    mini={true}
                    invert={true}
                    labelTitle={"Date of birth"}
                    value={practitionerDateOfBirth}
                    width={"50%"}
                    size={"70%"}
                  />
                </div>
                <TextInputField
                  readOnly={true}
                  mini={true}
                  invert={true}
                  labelTitle={"Username"}
                  value={userName}
                  width={"100%"}
                  size={"85%"}
                />
                <TextInputField
                  readOnly={true}
                  mini={true}
                  invert={true}
                  labelTitle={"Account Number"}
                  value={accountNumber}
                  width={"100%"}
                  size={"85%"}
                />
                <TextInputField
                  readOnly={true}
                  mini={true}
                  invert={true}
                  labelTitle={"Province"}
                  value={practititonerProvince}
                  width={"100%"}
                  size={"85%"}
                />
              </div>
              {/* approval for base user information */}
              <div
                style={{
                  flex: 0.5,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <TextInputField
                  readOnly={true}
                  mini={true}
                  invert={true}
                  labelTitle={"Type"}
                  value={practititonerType}
                  width={"100%"}
                  size={"85%"}
                />
                <TextInputField
                  readOnly={true}
                  mini={true}
                  invert={true}
                  labelTitle={"Status"}
                  value={practitionerStatus}
                  width={"100%"}
                  size={"85%"}
                />
                <TextInputField
                  readOnly={true}
                  mini={true}
                  invert={true}
                  labelTitle={"Pin"}
                  value={practionerPin}
                  width={"100%"}
                  size={"85%"}
                />
              </div>
            </div>
          </div>
          {/* manage Practioner data */}
          <div
            style={{
              height: practionerManage ? "100%" : 0,
              width: "100%",
              overflow: "hidden",
              position: "absolute",
              backgroundColor: BlackAndWhite.secondary,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              transition: "all 0.3s",
              zIndex: 400,
            }}
          >
            <div style={{ position: "absolute", top: 20, left: 20 }}>
              <CloseButton
                label={"X"}
                onClick={() => {
                  setPractionerManage(false);
                  setSelectedPractitionerManage(null);
                }}
              />
            </div>
            <div>
              {selectedPrcatitionerManage
                ? selectedPrcatitionerManage.firstName
                : "loading..."}
            </div>
          </div>
        </div>
      ) : null}
      <AddFacilityPopUp
        onSubmitClick={() => AddNewFacility()}
        ownerSlectedObject={ownerSlectedObject}
        setOwnerObject={setOwnerSlectedObject}
        ownerArray={ownerArray}
        imageLink={imageLink}
        setImageLink={setImageLink}
        onChangeImage={(e) => setImageLink(e.target.value)}
        onStoreChange={(e) => setStoreName(e.target.value)}
        // onOwnerChange={(e) => (e.target.value)}
        onCellNumberChange={(e) => setCellNumber(e.target.value)}
        onLandLineChange={(e) => setLandLine(e.target.value)}
        onEmailChange={(e) => setEmail(e.target.value)}
        onGoolgeLinkChange={(e) => setLoactionLink(e.target.value)}
        onStreetNumberChange={(e) => setStreetAddress(e.target.value)}
        onStreetNameChange={(e) => setStreetAddressName(e.target.value)}
        onSuburbChange={(e) => setSuburb(e.target.value)}
        onCityChange={(e) => setCity(e.target.value)}
        onPostalChange={(e) => setPostCode(e.target.value)}
        onPara1Change={(e) => setPara1(e.target.value)}
        onPara2Change={(e) => setPara2(e.target.value)}
        onProvinceChange={(e) => setProvince(e.target.value)}
        onStoreTypeChange={(e) => setType(e.target.value)}
        height={height}
        width={width}
        display={addFacilityPopUp}
        onCloseClick={() => setAddFacilityPopUp(false)}
        onRemuChange={(e) => setRemunirationPercentage(e.target.value)}
        onMonfromChange={(e) => setMonday(e.target.value)}
        onMonToChange={(e) => setMondayTo(e.target.value)}
        onTueFromChange={(e) => setTuesday(e.target.value)}
        onTueToChange={(e) => setTuesdayTo(e.target.value)}
        onWedFromChange={(e) => setWednesday(e.target.value)}
        onWedToChange={(e) => setWedTo(e.target.value)}
        onThurFromChange={(e) => setThurday(e.target.value)}
        onThurToChange={(e) => setThurTo(e.target.value)}
        onFriFromChange={(e) => setFriday(e.target.value)}
        onFriToChange={(e) => setFriTo(e.target.value)}
        onSatFromChange={(e) => setSaturday(e.target.value)}
        onSatToChange={(e) => setSatTo(e.target.value)}
        onSunFromChange={(e) => setSunday(e.target.value)}
        onSunToChange={(e) => setSunTo(e.target.value)}
        onPublicFromChange={(e) => setPublicTimes(e.target.value)}
        onPublicToChange={(e) => setPubTo(e.target.value)}
        statusValue={statusValue}
        onStatusChange={(e) => setStatusValue(e.target.value)}
      />
      <InvitePractioner
        display={invitePractionerPopUp}
        onCloseClick={() => setInvitePractionerPopUp(false)}
        admin={admin}
        setLoading={setLoading}
        loading={loading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        setInvitePractionerPopUp={setInvitePractionerPopUp}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <SelectOwnerPopUp
        ownerArray={ownerArray}
        onCloseClick={() => setCompletePopUp(false)}
        display={completedPopUp}
        selected={selectedOwner}
        setSelectedOwner={setSelectedOwner}
        // onCreateClick={() => registerNewFacility()}
        onCancelClick={() => setCompletePopUp(false)}
      />
      <FacilityPopUp
        updateListFetch={updateListFetch}
        setToastPop={setToastPop}
        setLoading={setLoading}
        setMessage={setMessage}
        setOpenFacilityDisplay={setOpenFacilityDisplay}
        setIsDeleting={setIsDeleting}
        selectedFacility={selectedFacility}
        deleteOption={isDeleting}
        display={
          openFacilityDisplay === true && subSelection === "All FACILITES"
            ? true
            : false
        }
        width={"100%"}
        height={"100%"}
        onCloseClick={() => {
          setOpenFacilityDisplay(false);
          setIsDeleting(false);
        }}
      />
      <LoadingPopUp display={loading} />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
    </div>
  );
};

export default Facilities;

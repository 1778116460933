import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import { BaseButtons } from "../../../../../../../../components/atoms";

const ProductListCard = ({
  productName,
  sku,
  category,
  subCat,
  size,
  unitMeasure,
  marginPrice,
  productPref,
  productFeature,
  retail,
  actions,
  onClick,
  image,
  warehouseSelect,
  onSelectClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={warehouseSelect ? onSelectClick : null}
      style={{
        width: "100%",
        height: 50,
        display: "flex",
        backgroundColor: hover ? "#9E3AC350" : BlackAndWhite.secondary,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div
          style={{
            height: "100%",
            width: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="icon"
            src={
              image === "Na"
                ? require("../../../../../../../../images/material-icons/editBlack.png")
                : image
            }
            style={{
              height: image === "Na" ? 20 : "70%",
              width: image === "Na" ? 20 : "70%",
            }}
          />
        </div>

        <div style={{ marginLeft: 5, width: "95%" }}>{productName}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ marginLeft: 5, width: "95%" }}>{sku}</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ marginLeft: 5, width: "95%" }}>{category}</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ marginLeft: 5, width: "95%" }}>{subCat}</div>
      </div>

      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ marginLeft: 5, width: "95%" }}>{productFeature}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ marginLeft: 5, width: "95%" }}>{productPref}</div>
      </div>
      <div
        style={{
          flex: 0.05,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        {size}
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        {unitMeasure}
      </div>
      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.07,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          {retail}
        </div>
      )}
      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.07,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          {marginPrice}
        </div>
      )}
      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60%",
              width: "100%",
            }}
          >
            <BaseButtons
              label={"MANAGE"}
              fontSize={12}
              height={20}
              marginTopFalse={true}
              size={"80%"}
              onClick={onClick}
              borderRadius={4}
            />
          </div>
        </div>
      )}
      <div
        style={{
          width: "99%",
          borderStyle: "solid",
          borderWidth: 0.5,
          borderColor: "#00000030",
          position: "absolute",
          bottom: 0,
        }}
      />
    </div>
  );
};

export default ProductListCard;

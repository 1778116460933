import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
  TextInputFieldSearch,
} from "../../../../../../components/atoms";
import SearchBar from "../../../../../canna-pos/components/patient-display/SearchBar";
import HeadersTitleFacilities from "../../../table-blocks/HeadersTitleFacilities";
import ProductListCard from "../../../product-uploader/components/list-view/components/product-list-card/ProductListCard";
import HeadersTitle from "../../../table-blocks/HeadersTitle";
import { ProductListHeader } from "../../../product-uploader/components/list-view/components";
import StoreSelectorHeader from "../store-selector-header/StoreSelectorHeader";
import { InventoryLinkCard } from "./components";

const UploadLinkedFacilitiesPopUp = ({
  display,
  onCloseClick,
  setLoading,
  setUploadItemPopUp,
  selectedProduct,
  setSelectedProd,
}) => {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState(null);

  const [facilitiesArray, setFacilitiesArray] = useState(null);
  const [searchDropDown, setSerachDropDown] = useState("");
  const [searchDropDownAddArray, setSerachDropDownAddArray] = useState(null);
  const [searchDropDisplayArray, setSerachDropDisplayArray] = useState(null);

  const NameAssemble = (product) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };
  console.log(selectedProduct);
  const SubmitProductData = () => {
    setUploadItemPopUp(false);
    setSelectedProd(null);
  };

  const SelectProductHandler = (product) => {
    setSelectedProd(product);
  };
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setFacilitiesArray(resData);
      });
    if (!display) {
      setUploadItemPopUp(false);
    }
  }, [display]);
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        transition: "all 0.3s",
        display: "flex",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton
          label={"X"}
          onClick={() => {
            setSelectedProd(null);
            setUploadItemPopUp(false);
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          height: "100%",
          justifyContent: "flex-start",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 22,
            width: "100%",
            marginTop: 25,
            marginBottom: 10,
          }}
        >
          <div style={{ marginLeft: 80 }}>LINK FACILITIES AND STOCK</div>
        </div>
        {/* search and info */}
        <div
          style={{
            width: "100%",
            textAlign: "left",
            marginTop: 20,
            display: "flex",
            marginBottom: 20,
            position: "relative",
          }}
        >
          <div style={{ marginLeft: 25, width: "40%" }}>
            {" "}
            Search or just select your Store you would like to add and stock you
            would like to assign the store
          </div>
          <div
            style={{
              display: "flex",
              width: "30%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  // width: 300,

                  borderWidth: 0.5,
                }}
              >
                <TextInputFieldSearch
                  placeholder={"Type here to search"}
                  height={25}
                  borderRadius={4}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <div
                  style={{
                    height: 30,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 20,
                  }}
                >
                  <div
                    onClick={() => setSearch("")}
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    CLEAR
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* this is to show accesble stock in storage/wahrehouse */}
          <div style={{ position: "absolute", right: 20, top: -60 }}>
            <div
              style={{
                height: 100,
                width: 300,
                borderRadius: 10,
                // backgroundColor: BlackAndWhite.secondary,
                // WebkitBoxShadow: "0px 0px 5px 1px #00000050",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderStyle: "solid",
                borderWidth: 0.5,
              }}
            >
              accessable stock
            </div>
          </div>
        </div>
        <StoreSelectorHeader />
        <div
          style={{
            flex: 1,
            width: "100%",

            overflow: "hidden",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            WebkitBoxShadow: "-2px 0px 5px 2px #00000050",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            {facilitiesArray
              ? facilitiesArray.store.map((store, index) => {
                  return search.toLocaleLowerCase().match(search) ? (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InventoryLinkCard
                        store={store}
                        NameAssemble={NameAssemble}
                        selectedProduct={selectedProduct}
                      />
                    </div>
                  ) : null;
                })
              : null}
            <div style={{ padding: 20 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadLinkedFacilitiesPopUp;

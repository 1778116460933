import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const TextInputFieldSearch = ({
  labelTitle,
  onChange,
  width,
  size,
  invert,
  rule,
  reqiredCheck,
  showPassword,
  autoFocus,
  value,
  placeholder,
  height,
  borderRadius,
  enter,
}) => {
  return (
    <form
      // onKeyDown={enter ? (e) => OnKeyDown(e) : null}
      onSubmit={
        enter
          ? (e) => {
              e.preventDefault();
              enter();
            }
          : (e) => e.preventDefault()
      }
      style={{
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <label
        style={{
          width: size ? size : 240,
          fontWeight: "500",
          fontSize: 16,
          color: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
          fontFamily: "Montserrat",
          position: "relative",
        }}
      >
        {labelTitle}
      </label>

      <input
        autoFocus={autoFocus}
        style={{
          height: height ? height : 30,
          width: size ? size : 220,
          // marginTop: 10,
          borderRadius: borderRadius ? borderRadius : 50,
          paddingRight: height ? 2 : 10,
          paddingTop: height ? 2 : 8,
          paddingBottom: height ? 2 : 8,
          paddingLeft: height ? 5 : 20,
          fontSize: height ? 12 : 18,
          fontFamily: "Montserrat",
          borderWidth: 0.2,
        }}
        type={showPassword ? "password" : "text"}
        name={labelTitle}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </form>
  );
};

export default TextInputFieldSearch;

import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
  TextInputFieldSearch,
} from "../../../../../../components/atoms";
import SearchBar from "../../../../../canna-pos/components/patient-display/SearchBar";
import HeadersTitleFacilities from "../../../table-blocks/HeadersTitleFacilities";
import ProductListCard from "../../../product-uploader/components/list-view/components/product-list-card/ProductListCard";
import HeadersTitle from "../../../table-blocks/HeadersTitle";
import { ProductListHeader } from "../../../product-uploader/components/list-view/components";

const UploadItem = ({
  display,
  onCloseClick,
  setLoading,
  setUploadItemPopUp,
  setUploadLinkedFaclitiesPopUp,
  selectedProduct,
  setSelectedProd,
}) => {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState(null);

  const WorkOutMargin = (product) => {
    const productBaseMargin =
      Number(product.costPrice) - Number(product.baseCostPrice);
    return productBaseMargin.toFixed(2);
  };
  const getProducts = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsAdded",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setProducts(respnseData.product);
        setLoading(false);
      } else if (respnseData.message) {
        setProducts(null);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };
  const NameAssemble = (product) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };

  const SubmitProductData = () => {
    setUploadItemPopUp(false);
    setSelectedProd(null);
  };

  const SubmitAndLinkFacilitiesData = () => {
    setUploadItemPopUp(false);
    setUploadLinkedFaclitiesPopUp(true);
  };

  const SelectProductHandler = (product) => {
    setSelectedProd(product);
  };
  useEffect(() => {
    getProducts();
    if (!display) {
      setUploadItemPopUp(false);
    }
  }, [display]);
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        transition: "all 0.3s",
        display: "flex",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton
          label={"X"}
          onClick={() => {
            setSelectedProd(null);
            setUploadItemPopUp(false);
          }}
        />
      </div>
      <div
        style={{
          flex: 0.7,
          height: "100%",
          justifyContent: "flex-start",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 22,
            width: "100%",
            marginTop: 25,
            marginBottom: 10,
          }}
        >
          <div style={{ marginLeft: 80 }}>UPLOAD PRODUCT</div>
        </div>
        {/* search and info */}
        <div
          style={{
            width: "100%",
            textAlign: "left",
            marginTop: 20,
            display: "flex",
            marginBottom: 20,
          }}
        >
          <div style={{ marginLeft: 25, width: "50%" }}>
            {" "}
            Seach or just selct your product you would like to bring into Stock
          </div>
          <div
            style={{
              display: "flex",
              width: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  // width: 300,

                  borderWidth: 0.5,
                }}
              >
                <TextInputFieldSearch
                  placeholder={"Type here to search"}
                  height={25}
                  borderRadius={4}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <div
                  style={{
                    height: 30,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 20,
                  }}
                >
                  <div
                    onClick={() => setSearch("")}
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    CLEAR
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProductListHeader warehouseSelect={true} />
        <div
          style={{
            flex: 1,
            width: "100%",

            overflow: "hidden",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            WebkitBoxShadow: "-2px 0px 5px 2px #00000050",
          }}
        >
          {selectedProduct ? (
            <div
              style={{
                height: 400,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // borderStyle: "solid",
                  padding: 10,
                  borderRadius: 8,
                  position: "relative",
                }}
              >
                <div style={{ marginBottom: 20 }}>
                  <img
                    alt="logo"
                    src={selectedProduct.imageUrl}
                    style={{ height: 200 }}
                  />
                </div>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>
                  {NameAssemble(selectedProduct)}
                </div>
                <div style={{ position: "absolute", right: 20, top: -20 }}>
                  <BaseButtons
                    label={"RESELECT"}
                    borderRadius={8}
                    mini={true}
                    marginTopFalse={true}
                    height={35}
                    onClick={() => setSelectedProd(null)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
              }}
            >
              {products
                ? products.map((product, index) => {
                    return NameAssemble(product)
                      .toLocaleLowerCase()
                      .match(search) ? (
                      <div key={index} style={{ width: "100%" }}>
                        <ProductListCard
                          warehouseSelect={true}
                          productName={NameAssemble(product)}
                          image={product.imageUrl}
                          sku={product.sku}
                          unitMeasure={product.quantityDenomiation}
                          subCat={product.productSubType}
                          size={product.size}
                          thcType={product.thcType}
                          marginPrice={
                            product ? WorkOutMargin(product) : "loading"
                          }
                          category={product.productType}
                          productPref={product.flavour}
                          productFeature={product.strain}
                          retail={product.retailPriceInc}
                          onSelectClick={() => {
                            setSelectedProd(product);
                          }}
                        />
                      </div>
                    ) : null;
                  })
                : null}
              <div style={{ padding: 20 }} />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          flex: 0.3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "85%",
            width: "80%",
            borderRadius: 8,
            WebkitBoxShadow: "0px 0px 5px 2px #00000050",
            backgroundColor: selectedProduct ? BlackAndWhite.secondary : "grey",
            opacity: selectedProduct ? 1 : 0.4,
            transition: "all 0.3s",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              style={{
                marginTop: 20,
                marginLeft: 20,
                fontWeight: "bold",
                fontSize: 18,
                marginRight: 20,
              }}
            >
              ADD STOCK AMOUNT & OTHER DETAILS
            </div>
          </div>
          <div
            style={{
              marginLeft: 20,
              fontSize: 14,
              marginRight: 20,
              marginTop: 20,
            }}
          >
            Amount of overall stock you wish to bring in with the product it can
            be a zero value...
          </div>
          <div style={{ marginTop: 20 }}>
            <TextInputField
              mini={true}
              invert={true}
              labelTitle={selectedProduct ? "Stock Amount " : "loading"}
              readOnly={selectedProduct ? false : true}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextAreaInput
              mini={true}
              invert={true}
              labelTitle={selectedProduct ? "Note " : "loading"}
              color={"#000000"}
              readOnly={selectedProduct ? false : true}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <BaseButtons
              label={"ADD TO STOCK"}
              mini={true}
              invert={false}
              borderRadius={8}
              marginTopFalse={true}
              size={220}
              disable={selectedProduct ? false : true}
              onClick={() => SubmitProductData()}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <BaseButtons
              label={"ASSIGN STORES & STOCK"}
              mini={true}
              invert={false}
              borderRadius={8}
              marginTopFalse={true}
              size={220}
              disable={selectedProduct ? false : true}
              onClick={() => SubmitAndLinkFacilitiesData()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadItem;

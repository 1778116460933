import React, { useState, useEffect } from "react";
import { LoadingPopUp } from "../../../../components";
import {
  HeadersInventory,
  InventoryDashboard,
  InventoryFilter,
  InventoryManageCard,
  StoresInventoryDisplayCard,
  UploadItem,
  UploadLinkedFacilitiesPopUp,
} from "./components";
import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";

const dummyData = ["test1", "test2", "test3"];

const Inventory = ({ subSelection, setSubSelection }) => {
  const [loading, setLoading] = useState(false);
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState(false);
  const [search, setSearch] = useState("");
  const [screen, setScreen] = useState("products");
  const [selectedProduct, setSelectedProd] = useState(null);

  const [uploadItemPopUp, setUploadItemPopUp] = useState(false);
  const [uploadLinkedFaclitiesPopUp, setUploadLinkedFaclitiesPopUp] =
    useState(false);

  // these are the varibales to allow the pop ups to open
  const [uploadStockPop, setUploadStockPop] = useState(false);

  const UploadProduct = () => {
    // here you will upload a new product into the wareg=house or my inventory
  };
  const AdjustStockLevel = () => {
    // this will allow for stock adjustment
  };
  const TransferPurchaseOrder = () => {
    // creates transfer stock order that needs to be recoeved by the store
  };
  const getProducts = () => {
    // get all proudcts in warehouse
  };

  const GetStockOfStore = () => {
    //  get store stock of products
  };

  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",

        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* //header tool bar */}
      {subSelection === "myInventory" ? (
        <>
          <div
            style={{
              height: 60,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <div style={{ marginLeft: 20, marginRight: 20 }}>MY INVENTORY</div> */}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              {/* ser=arch field */}
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    // width: 300,

                    borderWidth: 0.5,
                  }}
                >
                  <TextInputFieldSearch
                    placeholder={"Type here to search"}
                    height={25}
                    borderRadius={4}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <div
                    style={{
                      height: 30,
                      width: 70,
                      backgroundColor: BlackAndWhite.primary,
                      borderRadius: 4,
                      display: "flex",
                      color: BlackAndWhite.secondary,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => setSearch("")}
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      CLEAR
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ flex: 1 }}></div>
              <div style={{}}>
                <InventoryFilter filterName={"FILTER"} setScreen={setScreen} />
              </div>
            </div>
            <div style={{ marginRight: 10 }}>
              <BaseButtons
                label={"UPLOAD PRODUCT"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                height={35}
                size={180}
                onClick={() => setUploadItemPopUp(true)}
              />
            </div>
          </div>
          <HeadersInventory />
        </>
      ) : null}

      {subSelection === "myInventory" ? (
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {screen === "products" ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <InventoryManageCard />
            </div>
          ) : null}
          {screen === "stores" ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                overflowY: "auto",
                overflowX: "hidden",
                flexDirection: "column",
              }}
            >
              <StoresInventoryDisplayCard
                storeName={"MERRY-JANE LANGABAAN"}
                productTotal={107}
                data={dummyData}
                inStoreValue={"R 10 000.00"}
                type={"WALK IN"}
              />
              <StoresInventoryDisplayCard
                storeName={"MERRY-JANE LANGABAAN"}
                productTotal={107}
                data={dummyData}
                inStoreValue={"R 10 000.00"}
                type={"LOUNGE"}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {subSelection === "dashboard" ? <InventoryDashboard /> : null}
      {subSelection === "countStockTake" ? (
        <div style={{ width: "100%", flex: 1, borderStyle: "solid" }}>
          Count Stock Take
        </div>
      ) : null}
      <UploadItem
        selectedProduct={selectedProduct}
        setSelectedProd={setSelectedProd}
        setLoading={setLoading}
        display={
          uploadItemPopUp && subSelection === "myInventory" ? true : false
        }
        onCloseClick={() => setUploadItemPopUp(false)}
        setUploadItemPopUp={setUploadItemPopUp}
        setUploadLinkedFaclitiesPopUp={setUploadLinkedFaclitiesPopUp}
      />
      <UploadLinkedFacilitiesPopUp
        selectedProduct={selectedProduct}
        setSelectedProd={setSelectedProd}
        setLoading={setLoading}
        display={
          uploadLinkedFaclitiesPopUp && subSelection === "myInventory"
            ? true
            : false
        }
        onCloseClick={() => setUploadLinkedFaclitiesPopUp(false)}
        setUploadItemPopUp={setUploadLinkedFaclitiesPopUp}
      />
      <LoadingPopUp display={loading} newWidth={"100%"} />
    </div>
  );
};

export default Inventory;

import React, { useState, useContext, useEffect } from "react";
import {
  BaseButtons,
  CheckInput,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { ImageCheckMark, LoadingPopUp } from "../../../../../../components";
import ReadOnlyDisplay from "../read-only-display/ReadOnlyDisplay";
import StrainSelector from "../strain-selector/StrainSelector";
import MeasurementSelector from "../measurment-selector/MeasurementSelector";
import CompletedStepNotice from "../completed-step-notice/CompletedStepNotice";
import { AdminContext } from "../../../../../../context/admin-context";
import useWindowDimensions from "../../../../../../components/get-diemensions/UseWindowDiemensions";
import SubmitPopUp from "../list-view/components/submit-pop-up/SubmitPopUp";
import CategorySelectorPopUp from "../list-view/components/category-select-pop-up/CategorySelectorPopUp";
import MedicalUseCard from "./MedicalUseCard";
import UpsaleCard from "./UpsaleCard";

const AddCompletePath = ({
  setMessage,
  setToastPop,
  setSelectedAdd,
  selectedSubCategory,
  data,
  newLoad,
  setNewLoad,
  refresh,
  setRefresh,
  onCloseClick,
}) => {
  const [submitOpen, setSubmitOpen] = useState(false);
  const [cancelCheck, setCancelCheck] = useState(false);
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const admin = useContext(AdminContext);
  const [steps, setSteps] = useState(1);
  const [productName, setProductName] = useState(null);
  const [productType, setProductType] = useState(null);
  const [typeCheck, setTypeCheck] = useState("simple");

  const [meducalUseArray, setMeducalUseArray] = useState([]);

  const [medicalUseItem, setMedicalUseItem] = useState(null);

  const [upsaleQuestionArray, setUpsaleQuestionArray] = useState([]);

  const [upsellQuestion, setUpsellQuestion] = useState(null);

  const AddItemToMedicalArray = async () => {
    if (medicalUseItem) {
      await meducalUseArray.push(medicalUseItem);
      setMedicalUseItem("");
    } else {
      setToastPop(true);
      setMessage("None use avalible, please type what you would like to add");
    }
  };

  const AddItemToUpsaleArray = async () => {
    if (upsellQuestion) {
      await upsaleQuestionArray.push(upsellQuestion);
      setUpsellQuestion("");
    } else {
      setToastPop(true);
      setMessage(
        "None question avalible, please type what you would like to add"
      );
    }
  };
  const deleteMedicalUse = async ({ index }) => {
    if (medicalUseItem) {
      await meducalUseArray.splice(index, 1);
      setMeducalUseArray(meducalUseArray);
      setMedicalUseItem("");
    } else if (medicalUseItem === "") {
      await meducalUseArray.splice(index, 1);
      setMeducalUseArray(meducalUseArray);
      setMedicalUseItem("");
    }
  };
  const deleteUpsaleItem = async ({ index }) => {
    if (upsellQuestion) {
      await upsaleQuestionArray.splice(index, 1);
      setUpsellQuestion("");
    } else if (upsellQuestion === "") {
      await upsaleQuestionArray.splice(index, 1);
      setUpsellQuestion("");
    }
  };
  // console.log("medicaluse item", medicalUseItem);
  // console.log("meducalUseArray", meducalUseArray);

  const [categoryOpen, setCategoryOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const [subcategorySelected, setSubcategorySelected] = useState(null);

  const [strain, setStrain] = useState(null);
  const [strainOpen, setStrainOpen] = useState(false);

  const [growType, setGrowType] = useState(null);
  const [thcContent, setThcContent] = useState(null);
  const [thcType, setThcType] = useState("");

  const [measurement, setMeasurment] = useState("grams (g)");
  const [measurementOpen, setMeasuremntOpen] = useState(false);

  const [productSizeOrAmount, setProductSizeOrAmount] = useState(null);

  const [gender, setGender] = useState("nothing");
  const [flavour, setFlavour] = useState("None");

  const [inventory, setInventory] = useState(null);
  const [description, setDescription] = useState(null);
  const [menuDescription, setMenuDescription] = useState(null);
  const [useDescription, setUseDescription] = useState(null);
  const [productIngredients, setProductIngredients] = useState(null);

  const [imageLink, setImageLink] = useState(null);

  const [tag1, setTag1] = useState(null);
  const [tag2, setTag2] = useState(null);
  const [tag3, setTag3] = useState(null);

  const [supplier, setSupplier] = useState(null);
  const [brands, setBrands] = useState(null);

  const [productNo, setProductNo] = useState(null);
  const [productSubCat, setProductSubCat] = useState(null);

  const [grouping, setGrouping] = useState(null);

  const [image, setImage] = useState("coming soon");

  const [moreInfo, setMoreInfo] = useState(null);

  const [retailPrice, setRetailPrice] = useState("");
  const [retailPriceInc, setRetailPriceInc] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [merryJaneCostPrice, setMerryJaneCostPrice] = useState("");

  const [includesVat, setIncludesVat] = useState(true);
  const [sku, setSku] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [trackStock, setTrackStock] = useState(null);

  const [onlineStore, setOnlineStore] = useState(false);

  const [malemesbury, setMalmesbury] = useState(false);
  const [woodstock, setWoodstock] = useState(false);
  const [langebaan, setLangebaan] = useState(false);

  const [baseAdjusterOpen, setBaseAdjusterOpen] = useState("none");
  const [mjPercent, setMjPercent] = useState(0);
  const [costPercent, setCostPercent] = useState(0);
  const vatValue = 15;

  const valueCheckMerryJaneCost = (merryJaneCostPrice / 100) * mjPercent;
  const valueCheckCost = (costPrice / 100) * costPercent;
  const workOutVat = (retailPrice / 100) * vatValue;
  const workOutVaInclusive = (retailPriceInc / 100) * vatValue;
  const mjBasePriceDiff = costPrice - merryJaneCostPrice;
  const markUpBasePercent = (mjBasePriceDiff / merryJaneCostPrice) * 100;
  const mjBaseRetailDif = retailPrice - costPrice;
  const markRetailEarings = (mjBaseRetailDif / costPrice) * 100;

  const retailPercentMarkUpCheck = retailPriceInc - costPrice - workOutVat;
  const checkRetailInc = retailPercentMarkUpCheck;

  const UpDateInfoHandler = (e) => {
    // if (costPrice !== merryJaneCostPrice) {
    //   setCostPrice(merryJaneCostPrice);
    // }

    const vatValueCheck = Number(e.target.value / 100);
    const checkForVatOnCost = Number(vatValueCheck * 15);
    const updateVatValue = Number(e.target.value - checkForVatOnCost);
    setMerryJaneCostPrice(Number(e.target.value));
    setCostPrice(Number(e.target.value).toFixed(2));
    setRetailPrice(Number(e.target.value).toFixed(2));
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
    // let checkValue = Number(costPrice);
  };

  const UpdateRetailPricePlusVat = (e) => {
    setRetailPrice(Number(e.target.value));
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
  };

  const RetailExVatHandler = (e) => {
    setRetailPriceInc(Number(e.target.value));
    const vatRemove = workOutVat;
    const checkRetailEx = (Number(e.target.value) / 1.15).toFixed(2);

    setRetailPrice(checkRetailEx);
  };

  const WholeSaleHandler = (e) => {
    setCostPrice(Number(e.target.value));
    setRetailPrice(Number(e.target.value).toFixed(2));
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
  };

  // const UpdateRetailPricePlusVat = (e) => {
  //   setRetailPrice(e.target.value);
  //   setRetailPriceInc(e.target.value);
  //   // if (retailPrice === retailPriceInc) {
  //   //   setRetailPriceInc(Number(retailPrice) + Number(workOutVat));
  //   // }
  // };

  let checkSubCategory = () => {
    if (subcategorySelected) {
      return subcategorySelected.subCategoryName;
    } else {
      return "";
    }
  };
  let flavourCheck = () => {
    if (flavour !== "None") {
      return (
        productName +
        " | " +
        checkSubCategory() +
        " | " +
        thcType +
        " | " +
        thcContent +
        " | " +
        flavour
      );
    } else {
      return (
        productName +
        " | " +
        checkSubCategory() +
        " | " +
        thcType +
        " | " +
        thcContent
      );
    }
  };
  let displayedName = flavourCheck();
  let whichStores = [
    { storeName: "malemesbury", value: malemesbury },
    { storeName: "langebaan", value: langebaan },
    { storeName: "woodstock", value: woodstock },
  ];

  const uploadProduct = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/createProduct",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // step1
            productName: productName, //done
            productType: categorySelected.categoryName, //done
            type: typeCheck ? typeCheck : "Na", //done
            size: productSizeOrAmount, //done called measure
            strain: thcType ? thcType : "Na", // done
            growType: growType ? growType : "Na", //done
            thcContent: thcContent || "Na", //done
            thcType: thcType || "Na", //done
            // productNo: productNo || "random genearted test", // want to aut generate product number just need varibles that shiuld be used from john
            quantityDenomiation: measurement, //done
            gender: gender || "Na", // done
            flavour:
              flavour === "none" || flavour === "None" ? "None" : flavour, //done
            // step 2
            description: description,
            menuDescription: menuDescription,
            useDescription: useDescription,
            moreInfo: moreInfo || "none",
            medicalUses: meducalUseArray,
            productSubType: subcategorySelected.subCategoryName,
            grouping: "none",
            tag1: tag1,
            tag2: tag2 ? tag2 : "nothing",
            tag3: tag3 ? tag3 : "nothing",
            // step 3
            categoryId: categorySelected.categoryId,
            subCategoryId: subcategorySelected.subCategoryId,
            baseCostPrice: merryJaneCostPrice, //done
            retailPrice: retailPrice, //done
            retailPriceInc: retailPriceInc, //done
            vat: includesVat || "false", //done
            costPrice: costPrice, //done
            image: imageLink || "Na",
            addedBy:
              admin.adminUserData.firstName +
              " " +
              admin.adminUserData.lastName,
            inventory: inventory || "0",
            // step4
            onlineStore: onlineStore,
            availableAt: whichStores || "all",
            trackStock: "coming soon",
            sku: sku || "none",
            barcode: barcode || "none",
            upsaleQuestions: upsaleQuestionArray,
            supplier: supplier || "Merry-Jane",
            brands: brands || "Merry-Jane",
            ingredientDescription: productIngredients,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.newProduct) {
        // const wait = await updateListFetch();
        setToastPop(true);
        setMessage("Product Uploaded");
        setLoading(false);
        setNewLoad(newLoad ? false : true);
        setSelectedAdd(null);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("error", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [refresh]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        position: "relative",
        overflow: "scroll",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        backgroundColor: "#00000010",
      }}
    >
      {/* left block */}

      <div
        style={{
          width: "75%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",

          height: height > 726 ? 700 : 650,
        }}
      >
        {/* //primary section */}
        {/* product Type */}
        <div
          style={{
            minHeight: 150,
            width: "99%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 10,
              height: "95%",
              backgroundColor: BlackAndWhite.secondary,
              WebkitBoxShadow: "0px 0px 5px 1px #00000030",
            }}
          >
            <div style={{ width: "100%", textAlign: "left" }}>
              {" "}
              <div
                style={{
                  marginLeft: 44,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                }}
              >
                PRODUCT TYPE
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <CheckInput
                question={"Simple"}
                check={typeCheck === "simple" ? true : false}
                invert={true}
                onClick={() => setTypeCheck("simple")}
              />
              <CheckInput
                question={"Simple with variants"}
                check={typeCheck === "simplewithvariants" ? true : false}
                invert={true}
                onClick={() => setTypeCheck("simplewithvariants")}
              />
              <CheckInput
                question={"Composite"}
                check={typeCheck === "composite" ? true : false}
                invert={true}
                onClick={() => setTypeCheck("composite")}
              />
            </div>
          </div>
        </div>
        {/* primary information */}
        <div
          style={{
            minHeight: 1910,
            width: "99%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              // marginTop: 10,
              height: "95%",
              backgroundColor: BlackAndWhite.secondary,
              WebkitBoxShadow: "0px 0px 5px 1px #00000030",
            }}
          >
            <div style={{ width: "100%", textAlign: "left" }}>
              {" "}
              <div
                style={{
                  marginLeft: 44,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                }}
              >
                PRIMARY INFORMATION
              </div>
            </div>
            <TextInputField
              labelTitle={"POS Display Name (Auto Generated)"}
              invert={true}
              size={"90%"}
              mini={true}
              value={displayedName}
              readOnly={true}
              width={"100%"}
            />
            <TextInputField
              labelTitle={"Product Name ( Required )"}
              invert={true}
              size={"90%"}
              mini={true}
              onChange={(e) => setProductName(e.target.value)}
              width={"100%"}
            />
            {/* measure */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <TextInputField
                labelTitle={"Unit Size | Diemensions ( Required )"}
                invert={true}
                size={"80%"}
                mini={true}
                onChange={(e) => setProductSizeOrAmount(e.target.value)}
                width={"50%"}
              />
              {/* <div style={{ width: 20 }} /> */}
              <div style={{ width: "50%" }}>
                <MeasurementSelector
                  mini={true}
                  open={measurementOpen}
                  setType={setMeasurment}
                  type={measurement}
                  setTypeOpen={setMeasuremntOpen}
                  onTypeClick={() => setMeasuremntOpen(true)}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <TextInputField
                labelTitle={"SKU ( Required )"}
                invert={true}
                size={"80%"}
                mini={true}
                onChange={(e) => setSku(e.target.value)}
                width={"50%"}
              />
              {/* <div style={{ width: 20 }} /> */}
              <TextInputField
                labelTitle={"Barcode"}
                invert={true}
                size={"80%"}
                mini={true}
                onChange={(e) => setBarcode(e.target.value)}
                width={"50%"}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <TextInputField
                labelTitle={"Strength | Potency | Indicator"}
                invert={true}
                size={"80%"}
                mini={true}
                placeholder={"e.g 24% THC, 1000mg"}
                onChange={(e) => setThcContent(e.target.value)}
                width={"50%"}
              />
              {/* <div style={{ width: 20 }} /> */}
              <TextInputField
                labelTitle={"Product Feature"}
                invert={true}
                size={"80%"}
                mini={true}
                placeholder={"e.g. Full spectrum cannabaniod, Nano flower"}
                onChange={(e) => setThcType(e.target.value)}
                width={"50%"}
              />
            </div>
            {/* // grow conditions */}
            <div style={{ width: "100%", textAlign: "left" }}>
              {" "}
              <div
                style={{
                  marginLeft: 40,
                  fontSize: 14,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                }}
              >
                Product origin
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CheckInput
                check={growType === "indoor" ? true : false}
                question={"Indoor"}
                invert={true}
                size={200}
                onClick={() => setGrowType("indoor")}
              />
              <CheckInput
                check={growType === "greenhouse" ? true : false}
                question={"Greenhouse"}
                invert={true}
                size={200}
                onClick={() => setGrowType("greenhouse")}
              />
              <CheckInput
                check={growType === "outdoor" ? true : false}
                question={"Outdoor"}
                invert={true}
                size={200}
                onClick={() => setGrowType("outdoor")}
              />
              <CheckInput
                check={growType === "n/a" ? true : false}
                question={"N/A"}
                invert={true}
                size={200}
                onClick={() => setGrowType("n/a")}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <TextInputField
                labelTitle={"Product Preference"}
                invert={true}
                size={"90%"}
                width={"100%"}
                mini={true}
                onChange={(e) => setFlavour(e.target.value)}
                placeholder={
                  "e.g Chocolate, Melon blisk, Passion Punch, female"
                }
              />

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>Demographic</div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginTop: 5,
                  }}
                >
                  <CheckInput
                    mini={true}
                    question={"Male"}
                    invert={true}
                    size={120}
                    marginRight={5}
                    check={gender === "male" ? true : false}
                    onClick={() => setGender("male")}
                  />
                  <CheckInput
                    mini={true}
                    question={"Female"}
                    invert={true}
                    size={120}
                    marginRight={5}
                    check={gender === "female" ? true : false}
                    onClick={() => setGender("female")}
                  />
                  <CheckInput
                    mini={true}
                    question={"Both"}
                    invert={true}
                    size={120}
                    marginRight={5}
                    check={gender === "both" ? true : false}
                    onClick={() => setGender("both")}
                  />
                  <CheckInput
                    mini={true}
                    question={"N/A"}
                    invert={true}
                    size={120}
                    marginRight={5}
                    check={gender === "n/a" ? true : false}
                    onClick={() => setGender("n/a")}
                  />
                </div>
              </div> */}
            </div>
            {/* // descriptions */}
            <TextAreaInput
              size={"91%"}
              width={"100%"}
              mini={true}
              labelTitle={"Full Product Description"}
              color={BlackAndWhite.primary}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextAreaInput
              size={"91%"}
              width={"100%"}
              mini={true}
              labelTitle={"Short Product Description ( max 150 characters)"}
              color={BlackAndWhite.primary}
              onChange={(e) => setMenuDescription(e.target.value)}
            />
            <TextAreaInput
              size={"91%"}
              width={"100%"}
              mini={true}
              labelTitle={"Product Details"}
              color={BlackAndWhite.primary}
              onChange={(e) => setMoreInfo(e.target.value)}
            />
            <TextAreaInput
              size={"91%"}
              width={"100%"}
              mini={true}
              labelTitle={"Product Ingredients"}
              color={BlackAndWhite.primary}
              onChange={(e) => setProductIngredients(e.target.value)}
            />
            <TextAreaInput
              size={"91%"}
              width={"100%"}
              mini={true}
              labelTitle={"Product Usage"}
              color={BlackAndWhite.primary}
              onChange={(e) => setUseDescription(e.target.value)}
            />
          </div>
        </div>
        {/* Pricing */}
        <div
          style={{
            minHeight: 250,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              // marginTop: 10,
              height: "85%",
              backgroundColor: BlackAndWhite.secondary,
              WebkitBoxShadow: "0px 0px 5px 1px #00000030",
            }}
          >
            <div style={{ width: "100%", textAlign: "left" }}>
              {" "}
              <div
                style={{
                  marginLeft: 40,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                }}
              >
                COSTING / PRINCING
              </div>
            </div>
            <div
              style={{
                width: "100%",
                position: "relative",
                height: 80,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <TextInputField
                labelTitle={"Cost Excl"}
                invert
                size={100}
                onChange={(e) => UpDateInfoHandler(e)}
                value={merryJaneCostPrice}
                mini={true}
                height={20}
                type={"number"}
                enter={() =>
                  setMerryJaneCostPrice(merryJaneCostPrice.toFixed(2))
                }
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextInputField
                  type={"number"}
                  height={20}
                  mini={true}
                  labelTitle={"W-sale Excl"}
                  // autoFocus={true}
                  invert
                  size={100}
                  onChange={(e) => {
                    WholeSaleHandler(e);
                  }}
                  value={costPrice}
                  amountValue={mjPercent}
                  amountMarkedUp={
                    "R" +
                    " " +
                    (
                      Number(valueCheckMerryJaneCost) + Number(costPrice)
                    ).toFixed(2)
                  }
                  enter={() => setCostPrice(costPrice.toFixed(2))}
                />
                <div
                  style={{
                    width: 60,
                    marginLeft: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                    fontSize: 12,
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 14, marginBottom: 5, opacity: 0.5 }}>
                    Mark up
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // borderStyle: "solid",
                      borderWidth: 0.5,
                      height: 37,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#00000030",
                    }}
                  >
                    <div>{"R" + " " + mjBasePriceDiff.toFixed(2)}</div>
                    <div>{markUpBasePercent.toFixed(2) + "%"}</div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextInputField
                  type={"number"}
                  labelTitle={"Retail Excl"}
                  invert
                  size={100}
                  onChange={(e) => UpdateRetailPricePlusVat(e)}
                  value={retailPrice}
                  mini={true}
                  height={20}
                  enter={() => setRetailPrice(retailPrice.toFixed(2))}
                />
                <div
                  style={{
                    width: 60,
                    marginLeft: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                    fontSize: 12,
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 14, marginBottom: 5, opacity: 0.5 }}>
                    Mark up
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // borderStyle: "solid",
                      borderWidth: 0.5,
                      height: 37,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#00000030",
                    }}
                  >
                    <div>
                      {"R" + " " + Number(retailPrice - costPrice).toFixed(2)}
                    </div>
                    <div>{markRetailEarings.toFixed(2) + "%"}</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextInputField
                  type={"number"}
                  labelTitle={"Retail Inc Vat"}
                  invert
                  size={100}
                  onChange={(e) => RetailExVatHandler(e)}
                  value={retailPriceInc}
                  mini={true}
                  height={20}
                  enter={() => setRetailPriceInc(retailPriceInc.toFixed(2))}
                />
                <div
                  style={{
                    width: 60,
                    marginLeft: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                    fontSize: 12,
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 14, marginBottom: 5, opacity: 0.5 }}>
                    VAT
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // borderStyle: "solid",
                      borderWidth: 0.5,
                      height: 37,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#00000030",
                    }}
                  >
                    <div>R {workOutVaInclusive.toFixed(2)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Save and close menu options */}
        <div
          style={{
            minHeight: 250,
            width: "99%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 20,
              height: "85%",
              backgroundColor: BlackAndWhite.secondary,
              WebkitBoxShadow: "0px 0px 5px 1px #00000030",
            }}
          >
            <div
              style={{
                width: submitOpen ? "100%" : 0,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: submitOpen ? 30 : 0,
                transition: "all 0.3s",
                marginTop: 20,
              }}
            >
              Would you like to upload your new product?
            </div>
            <div
              style={{
                width: cancelCheck ? "100%" : 0,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: cancelCheck ? 30 : 0,
                transition: "all 0.3s",
              }}
            >
              your product hasnt been saved would you still like to cancel?
            </div>
            <div
              style={{
                width: !cancelCheck && !submitOpen ? "100%" : 0,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: !cancelCheck && !submitOpen ? 30 : 0,
                transition: "all 0.3s",
              }}
            >
              COMPLETE THE PROCESS
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              {submitOpen ? (
                <BaseButtons
                  label={"UPLOAD"}
                  disable={
                    productName && sku && barcode && retailPriceInc
                      ? false
                      : true
                  }
                  onClick={() => uploadProduct()}
                />
              ) : null}
              {submitOpen ? (
                <BaseButtons
                  label={"CANCEL"}
                  onClick={() => setSubmitOpen(false)}
                />
              ) : null}
              {cancelCheck ? (
                <BaseButtons label={"YES"} onClick={onCloseClick} />
              ) : null}
              {cancelCheck ? (
                <BaseButtons
                  label={"NO"}
                  onClick={() => setCancelCheck(false)}
                />
              ) : null}
              {submitOpen || cancelCheck ? null : (
                <BaseButtons
                  label={"SUBMIT"}
                  onClick={() => setSubmitOpen(true)}
                />
              )}
              {submitOpen || cancelCheck ? null : (
                <BaseButtons
                  label={"CANCEL"}
                  onClick={() => setCancelCheck(true)}
                />
              )}
            </div>
            <div style={{ height: 20 }}></div>
          </div>
        </div>
        <div style={{ padding: 700 }} />
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <TextInputField
                labelTitle={"Thc content:"}
                invert={true}
                size={250}
                mini={true}
                onChange={(e) => setThcContent(e.target.value)}
              />

          <div style={{ width: 20 }} />
          <StrainSelector
            mini={true}
            open={strainOpen}
            edibles={[
              "Nano",
              "Indica Hybrid",
              "Pure Indica",
              "Sativa Hybrid",
              "Pure Sativa",
              "Balanced",
            ]}
            setStrain={setThcType}
            strain={thcType}
            setStrainOpen={setStrainOpen}
            onStrainClick={() => setStrainOpen(true)}
          />
        </div> */}
      </div>

      {/* right block */}
      <div
        style={{
          width: "25%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {/* images */}
        <div
          style={{
            minHeight: 380,
            width: "99%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",

              height: "95%",
              backgroundColor: BlackAndWhite.secondary,
              WebkitBoxShadow: "0px 0px 5px 1px #00000030",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                // alignItems: "center",
                position: "relative",
                height: 20,
              }}
            ></div>
            <div
              style={{
                width: "100%",
                marginBottom: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  height: 200,
                  width: 200,
                  // backgroundColor: "#00000050",
                  borderStyle: "solid",
                  borderWidth: 0.5,
                }}
              >
                <img
                  alt="icon"
                  src={`${imageLink}`}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
              <TextInputField
                invert={true}
                labelTitle={"Image Link"}
                width={"100%"}
                size={"80%"}
                mini={true}
                onChange={(e) => setImageLink(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* tags */}
        <div
          style={{
            minHeight: 300,
            width: "99%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",

              height: "95%",
              backgroundColor: BlackAndWhite.secondary,
              WebkitBoxShadow: "0px 0px 5px 1px #00000030",
            }}
          >
            <div style={{ width: "100%", textAlign: "left" }}>
              {" "}
              <div
                style={{
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                CATEGORIZE
              </div>
            </div>
            <BaseButtons
              label={
                categorySelected
                  ? categorySelected.categoryName
                  : "Select Category"
              }
              mini={true}
              size={250}
              height={subcategorySelected ? 30 : 50}
              onClick={() => setCategoryOpen(true)}
              marginTopFalse={true}
            />
            {subcategorySelected ? (
              <div
                style={{
                  backgroundColor: "#FAA919",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                {subcategorySelected.subCategoryName}
              </div>
            ) : null}
            <TextInputField
              labelTitle={"Tag 1"}
              invert={true}
              size={"70%"}
              mini={true}
              width={"100%"}
              onChange={(e) => setTag1(e.target.value)}
            />
            <TextInputField
              labelTitle={"Tag 2"}
              invert={true}
              size={"70%"}
              mini={true}
              width={"100%"}
              onChange={(e) => setTag2(e.target.value)}
            />
            <TextInputField
              labelTitle={"Tag 3"}
              invert={true}
              size={"70%"}
              mini={true}
              width={"100%"}
              onChange={(e) => setTag3(e.target.value)}
            />
            {/* <TextInputField
              labelTitle={"Inventory" + " in: " + measurement}
              invert={true}
              size={"70%"}
              mini={true}
              width={"100%"}
              onChange={(e) => setInventory(e.target.value)}
            /> */}
            <TextInputField
              labelTitle={"Brand"}
              invert={true}
              size={"70%"}
              mini={true}
              width={"100%"}
              onChange={(e) => setBrands(e.target.value)}
            />
            <TextInputField
              labelTitle={"Supplier"}
              invert={true}
              size={"70%"}
              mini={true}
              width={"100%"}
              onChange={(e) => setSupplier(e.target.value)}
            />
            <div style={{ height: 20 }} />
          </div>
        </div>
        {/* medical uses */}
        <div
          style={{
            minHeight: 500,
            width: "99%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",

              height: meducalUseArray.length >= 6 ? "95%" : 450,
              backgroundColor: BlackAndWhite.secondary,
              WebkitBoxShadow: "0px 0px 5px 1px #00000030",
            }}
          >
            <div style={{ width: "100%", textAlign: "left" }}>
              {" "}
              <div
                style={{
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                MEDICAL USES
              </div>
              {meducalUseArray.length >= 1 ? (
                <div
                  style={{
                    marginLeft: 20,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: BlackAndWhite.primary,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  USES LIST BELOW:
                </div>
              ) : null}
              {meducalUseArray.map((use, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      height: 45,
                      marginTop: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    // onClick={}
                  >
                    <MedicalUseCard
                      use={use}
                      onDeleteClick={() => deleteMedicalUse(index)}
                    />
                  </div>
                );
              })}
              <TextInputField
                invert={true}
                labelTitle={"Medical assitance"}
                placeholder={"e.g Appetite"}
                width={"100%"}
                size={"80%"}
                mini={true}
                onChange={(e) => setMedicalUseItem({ use: e.target.value })}
                value={medicalUseItem ? medicalUseItem.use : medicalUseItem}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <BaseButtons
                  label={"Add use"}
                  mini={true}
                  marginTopFalse={true}
                  // width={ "90%" }
                  size={"90%"}
                  onClick={() => AddItemToMedicalArray()}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Upsale questions */}
        <div
          style={{
            minHeight: 500,
            width: "99%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",

              height: upsaleQuestionArray.length >= 4 ? "95%" : 500,
              backgroundColor: BlackAndWhite.secondary,
              WebkitBoxShadow: "0px 0px 5px 1px #00000030",
            }}
          >
            <div style={{ width: "100%", textAlign: "left" }}>
              {" "}
              <div
                style={{
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                UPSALE QUESTIONS
              </div>
              {upsaleQuestionArray.length >= 1 ? (
                <div
                  style={{
                    marginLeft: 20,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: BlackAndWhite.primary,
                    marginTop: 10,
                    marginBottom: 10,
                    width: "100%",
                  }}
                >
                  ACTIVE QUESTIONS BELOW:
                </div>
              ) : null}
              {upsaleQuestionArray.map((use, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      height: 45,
                      marginTop: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                    // onClick={}
                  >
                    <UpsaleCard
                      use={use}
                      onDeleteClick={() => deleteUpsaleItem(index)}
                    />
                  </div>
                );
              })}
              <TextInputField
                invert={true}
                labelTitle={"Question?"}
                placeholder={"e.g do you have rolling papers ?"}
                width={"100%"}
                size={"80%"}
                mini={true}
                onChange={(e) =>
                  setUpsellQuestion({ question: e.target.value })
                }
                value={
                  upsellQuestion ? upsellQuestion.question : upsellQuestion
                }
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <BaseButtons
                  label={"Add Question"}
                  mini={true}
                  marginTopFalse={true}
                  // width={ "90%" }
                  size={"90%"}
                  onClick={() => AddItemToUpsaleArray()}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            marginTop: 10,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* <div
              style={{
                fontSize: 12,
                marginBottom: 10,
                marginTop: 10,
                width: "100%",
                textAlign: "left",
              }}
            >
              SELECT AVAILABLITY
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <CheckInput
                mini={true}
                question={"online store"}
                invert={true}
                size={120}
                marginRight={5}
                check={onlineStore === "online store" ? true : false}
                onClick={() => setOnlineStore("online store")}
              />
              <CheckInput
                mini={true}
                question={"In-store"}
                invert={true}
                size={120}
                marginRight={5}
                check={onlineStore === "in-store" ? true : false}
                onClick={() => setOnlineStore("in-store")}
              />
              <CheckInput
                mini={true}
                question={"Both"}
                invert={true}
                size={120}
                check={onlineStore === "both" ? true : false}
                onClick={() => setOnlineStore("both")}
              />
            </div> */}
          </div>
        </div>
      </div>

      <CategorySelectorPopUp
        display={categoryOpen}
        categoryArray={data}
        onCloseClick={() => setCategoryOpen(false)}
        setCategorySelected={setCategorySelected}
        setCategoryOpen={setCategoryOpen}
        setSubcategorySelected={setSubcategorySelected}
        categorySelected={categorySelected}
        setToastPop={setToastPop}
        setMessage={setMessage}
        selectedSubCategory={subcategorySelected}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default AddCompletePath;

import React, { useState } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";

const InventoryManageCard = ({ store, NameAssemble, selectedProduct }) => {
  const [areSure, setAreSure] = useState(false);
  return (
    <div
      style={{
        height: 70,
        width: "100%",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#00000030",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 15, width: "100%", textAlign: "left" }}>
          PRODUCT NAME
        </div>
      </div>

      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "center" }}>
          SKU
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
          SUPPLIER
        </div>
      </div>
      <div
        style={{
          flex: 0.25,

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {/* <div
            style={{
              fontSize: 14,
              marginTop: 15,
              marginRight: 5,
            }}
          >
            UNIT
          </div> */}
        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={70}
            labelTitle={"Total"}
            type={"Number"}
            readOnly={true}
          />
        </div>
        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={70}
            labelTitle={"In Store's"}
            type={"Number"}
            readOnly={true}
          />
        </div>
        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={70}
            labelTitle={"Available"}
            type={"Number"}
            readOnly={true}
          />
        </div>
      </div>

      <div
        style={{
          flex: 0.25,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <BaseButtons
          label={"DISTRIBUTION"}
          mini={true}
          marginTopFalse={true}
          borderRadius={8}
          height={35}
        />
      </div>
    </div>
  );
};

export default InventoryManageCard;

import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const UserItem = ({
  role,
  firstName,
  surname,
  store,
  email,
  cell,
  onManageClick,
  status,
  province,
}) => {
  const [hover, setHover] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "100%",
        height: 50,
        // borderStyle: "solid",
        display: "flex",
        fontSize: 12,
        backgroundColor: hover ? "#9E3AC350" : BlackAndWhite.secondary,
        cursor: "pointer",
        position: "relative",
        transition: "all 0.3s",
      }}
    >
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: 12,
        }}
      >
        {" "}
        <div style={{ width: "100%" }}>
          <div style={{ marginLeft: 10 }}> {role}</div>
        </div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: 12,
        }}
      >
        <div style={{ width: "100%" }}>{firstName}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: 12,
        }}
      >
        <div style={{ width: "100%" }}>{surname}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: 12,
        }}
      >
        <div style={{ width: "100%" }}>{store}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: 12,
        }}
      >
        <div style={{ width: "100%" }}>{province}</div>
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: 12,
        }}
      >
        <div style={{ width: "100%", textOverflow: "ellipsis" }}>{email}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: 12,
        }}
      >
        <div style={{ width: "100%" }}>{cell}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: 12,
        }}
      >
        <div style={{ width: "100%" }}>{status}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            backgroundColor: BlackAndWhite.primary,
            borderRadius: 4,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            color: BlackAndWhite.secondary,
            cursor: "pointer",
          }}
        >
          Block
        </div>
        <div style={{ width: 20 }} /> */}
        <div
          onClick={onManageClick}
          onMouseOver={() => setButtonHover(true)}
          onMouseOut={() => setButtonHover(false)}
          style={{
            backgroundColor: buttonHover
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
            borderRadius: 4,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            color: buttonHover
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
            cursor: "pointer",
            transition: "all 0.3s",
          }}
        >
          Manage
        </div>
      </div>
      <div
        style={{
          height: 1.5,
          width: "100%",
          backgroundColor: BlackAndWhite.primary,
          position: "absolute",
          bottom: 0,
          opacity: 0.5,
        }}
      />
    </div>
  );
};

export default UserItem;

import React, { useState } from "react";
import StatCard from "./stat-card/StatCard";
import { BlackAndWhite } from "../../../themes/Colors";
import { BaseButtons } from "../../../components/atoms";
import StatPopUp from "./stat-pop-up/StatPopUp";
import { Bar, Pie, Line, Stacked, Doughnut } from "react-chartjs-2";
import StatCardType from "./stat-card/StatCardType";

const DashBoard = ({
  patientStats,
  onRefreshClick,
  loading,
  prescriptionStats,
  patientStatsAvalible,
  height,
  productStats,
  storeStats,
  staffStats,
}) => {
  const [statType, setStatType] = useState(null);
  const [storeUsageHover, setStoreUsageHover] = useState(false);

  const labels = [
    `Malmesbury `,
    `Woodstock `,
    `Langebaan `,
    `Mossel Bay`,
    `Worcester`,
    `Panorama`,
    `Hermanus`,
    `Wellington`,
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Favourite Store", // Setting up the label for the dataset
        labels: labels,
        backgroundColor: [
          "#48A14D",
          "#008080",
          "#B33F40",
          "#049BE5",
          "#9E3AC3",
        ], // Setting up the background color for the dataset
        borderColor: ["#48A14D", "#008080", "#B33F40", "#049BE5", "#9E3AC3"], // Setting up the border color for the dataset
        data: [
          patientStats ? patientStats.stats.favStores.malmesbury : "loading",
          patientStats ? patientStats.stats.favStores.woodstock : "loading",
          patientStats ? patientStats.stats.favStores.langabaan : "loading",
          patientStats ? patientStats.stats.favStores.mosselBay : "loading",
          patientStats ? patientStats.stats.favStores.worcester : "loading",
          patientStats ? patientStats.stats.favStores.panorama : "loading",
          patientStats ? patientStats.stats.favStores.hermanus : "loading",
          patientStats ? patientStats.stats.favStores.wellington : "loading",
        ],
        // Setting up the data for the dataset
      },
    ],
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        position: "relative",
        overflowX: "hidden",
      }}
    >
      {/* <div
        style={{
          color: BlackAndWhite.secondary,
          fontSize: 26,
          marginLeft: 40,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        STATS
      </div> */}

      <StatCardType
        productStats={productStats}
        onClick={patientStatsAvalible ? () => setStatType("patient") : null}
        title={"REGISTRATIONS"}
        type={"registration"}
        icon={require("../../../images/material-icons/profileBlack.png")}
        iconHeight={40}
        iconWidth={40}
        item1Title={"TOTAL"}
        item1Value={
          patientStats ? patientStats.stats.allPatinetsCount : "loading"
        }
        item2Title={"YESTERDAY"}
        item2Value={patientStats ? patientStats.stats.yesterday : "loading"}
        item3Title={"TODAY"}
        item3Value={patientStats ? patientStats.stats.today : "loading"}
        item4Title={"LAST 30 DAYS"}
        item4Value={
          patientStats
            ? patientStats.stats.today +
              patientStats.stats.yesterday +
              patientStats.stats.back2days +
              patientStats.stats.back3days +
              patientStats.stats.back4days +
              patientStats.stats.back5days +
              patientStats.stats.back6days +
              patientStats.stats.back7days +
              patientStats.stats.back8days +
              patientStats.stats.back9days +
              patientStats.stats.back10days +
              patientStats.stats.back11days +
              patientStats.stats.back12days +
              patientStats.stats.back13days +
              patientStats.stats.back14days +
              patientStats.stats.back15days +
              patientStats.stats.back16days +
              patientStats.stats.back17days +
              patientStats.stats.back18days +
              patientStats.stats.back19days +
              patientStats.stats.back20days +
              patientStats.stats.back21days +
              patientStats.stats.back22days +
              patientStats.stats.back23days +
              patientStats.stats.back24days +
              patientStats.stats.back25days +
              patientStats.stats.back26days +
              patientStats.stats.back27days +
              patientStats.stats.back28days +
              patientStats.stats.back29days
            : "loading"
        }
      />
      <StatCardType
        productStats={productStats}
        // onClick={patientStatsAvalible ? () => setStatType("patient") : null}
        title={"PRESCRIPTIONS"}
        type={"registration"}
        icon={require("../../../images/material-icons/prescriptionBlack.png")}
        iconHeight={40}
        iconWidth={40}
        item1Title={"ACTIVE"}
        item1Value={
          prescriptionStats ? prescriptionStats.stats.approved : "loading"
        }
        item2Title={"PENDING"}
        item2Value={
          prescriptionStats ? prescriptionStats.stats.pending : "loading"
        }
        item3Title={"EXIPRED"}
        item3Value={
          prescriptionStats ? prescriptionStats.stats.expired : "loading"
        }
        item4Title={"FLAGGED"}
        item4Value={
          prescriptionStats ? prescriptionStats.stats.flagged : "loading"
        }
      />

      {/* store stats */}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <div
          onMouseOver={() => setStoreUsageHover(true)}
          onMouseOut={() => setStoreUsageHover(false)}
          style={{
            width: "95%",
            height: height >= 800 ? 420 : 350,
            borderRadius: 25,
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: storeUsageHover
              ? "0px 0px 12px 2px #00000083"
              : "0px 0px 10px 1px #00000033",
            marginTop: 14,
            // marginLeft: 20,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            transition: "all 0.3s",
            // marginRight: 20,
          }}
        >
          <div
            style={{
              fontSize: 22,
              fontWeight: "bold",
              width: "100%",
              marginTop: 20,
            }}
          >
            <div style={{ marginLeft: 20, color: "#00000090" }}>
              PREFFERED STORES
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              // justifyContent: "space-evenly",
              flexWrap: "wrap",
              // alignItems: "center",
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 4 }}>Malmesbury</div>
              <div style={{ fontWeight: "bold" }}>
                {patientStats
                  ? (
                      (patientStats.stats.favStores.malmesbury /
                        (Number(patientStats.stats.favStores.woodstock) +
                          Number(patientStats.stats.favStores.malmesbury) +
                          Number(patientStats.stats.favStores.mosselBay) +
                          Number(patientStats.stats.favStores.worcester) +
                          Number(patientStats.stats.favStores.panorama) +
                          Number(patientStats.stats.favStores.hermanus) +
                          Number(patientStats.stats.favStores.wellington) +
                          Number(patientStats.stats.favStores.langabaan))) *
                      100
                    ).toFixed(2) + "%"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 4 }}>Woodstock</div>
              <div style={{ fontWeight: "bold" }}>
                {patientStats
                  ? (
                      (patientStats.stats.favStores.woodstock /
                        (Number(patientStats.stats.favStores.woodstock) +
                          Number(patientStats.stats.favStores.malmesbury) +
                          Number(patientStats.stats.favStores.mosselBay) +
                          Number(patientStats.stats.favStores.worcester) +
                          Number(patientStats.stats.favStores.panorama) +
                          Number(patientStats.stats.favStores.hermanus) +
                          Number(patientStats.stats.favStores.wellington) +
                          Number(patientStats.stats.favStores.langabaan))) *
                      100
                    ).toFixed(2) + "%"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 4 }}>Langebaan</div>
              <div style={{ fontWeight: "bold" }}>
                {patientStats
                  ? (
                      (patientStats.stats.favStores.langabaan /
                        (Number(patientStats.stats.favStores.woodstock) +
                          Number(patientStats.stats.favStores.malmesbury) +
                          Number(patientStats.stats.favStores.mosselBay) +
                          Number(patientStats.stats.favStores.worcester) +
                          Number(patientStats.stats.favStores.panorama) +
                          Number(patientStats.stats.favStores.hermanus) +
                          Number(patientStats.stats.favStores.wellington) +
                          Number(patientStats.stats.favStores.langabaan))) *
                      100
                    ).toFixed(2) + "%"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 4, marginTop: 5 }}>
                Mossel Bay
              </div>
              <div style={{ fontWeight: "bold" }}>
                {patientStats
                  ? (
                      (patientStats.stats.favStores.mosselBay /
                        (Number(patientStats.stats.favStores.woodstock) +
                          Number(patientStats.stats.favStores.malmesbury) +
                          Number(patientStats.stats.favStores.mosselBay) +
                          Number(patientStats.stats.favStores.worcester) +
                          Number(patientStats.stats.favStores.panorama) +
                          Number(patientStats.stats.favStores.hermanus) +
                          Number(patientStats.stats.favStores.wellington) +
                          Number(patientStats.stats.favStores.langabaan))) *
                      100
                    ).toFixed(2) + "%"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 4, marginTop: 5 }}>
                Worcester
              </div>
              <div style={{ fontWeight: "bold" }}>
                {patientStats
                  ? (
                      (patientStats.stats.favStores.worcester /
                        (Number(patientStats.stats.favStores.woodstock) +
                          Number(patientStats.stats.favStores.malmesbury) +
                          Number(patientStats.stats.favStores.mosselBay) +
                          Number(patientStats.stats.favStores.worcester) +
                          Number(patientStats.stats.favStores.panorama) +
                          Number(patientStats.stats.favStores.hermanus) +
                          Number(patientStats.stats.favStores.wellington) +
                          Number(patientStats.stats.favStores.langabaan))) *
                      100
                    ).toFixed(2) + "%"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 4, marginTop: 5 }}>
                Panorama
              </div>
              <div style={{ fontWeight: "bold" }}>
                {patientStats
                  ? (
                      (patientStats.stats.favStores.panorama /
                        (Number(patientStats.stats.favStores.woodstock) +
                          Number(patientStats.stats.favStores.malmesbury) +
                          Number(patientStats.stats.favStores.mosselBay) +
                          Number(patientStats.stats.favStores.worcester) +
                          Number(patientStats.stats.favStores.panorama) +
                          Number(patientStats.stats.favStores.hermanus) +
                          Number(patientStats.stats.favStores.wellington) +
                          Number(patientStats.stats.favStores.langabaan))) *
                      100
                    ).toFixed(2) + "%"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 4, marginTop: 5 }}>
                Hermanus
              </div>
              <div style={{ fontWeight: "bold" }}>
                {patientStats
                  ? (
                      (patientStats.stats.favStores.hermanus /
                        (Number(patientStats.stats.favStores.woodstock) +
                          Number(patientStats.stats.favStores.malmesbury) +
                          Number(patientStats.stats.favStores.mosselBay) +
                          Number(patientStats.stats.favStores.worcester) +
                          Number(patientStats.stats.favStores.panorama) +
                          Number(patientStats.stats.favStores.hermanus) +
                          Number(patientStats.stats.favStores.wellington) +
                          Number(patientStats.stats.favStores.langabaan))) *
                      100
                    ).toFixed(2) + "%"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 4, marginTop: 5 }}>
                Wellington
              </div>
              <div style={{ fontWeight: "bold" }}>
                {patientStats
                  ? (
                      (patientStats.stats.favStores.wellington /
                        (Number(patientStats.stats.favStores.woodstock) +
                          Number(patientStats.stats.favStores.malmesbury) +
                          Number(patientStats.stats.favStores.mosselBay) +
                          Number(patientStats.stats.favStores.worcester) +
                          Number(patientStats.stats.favStores.panorama) +
                          Number(patientStats.stats.favStores.hermanus) +
                          Number(patientStats.stats.favStores.wellington) +
                          Number(patientStats.stats.favStores.langabaan))) *
                      100
                    ).toFixed(2) + "%"
                  : "loading"}
              </div>
            </div>
          </div>
          <div style={{ width: "95%", marginTop: 10 }}>
            <Bar
              width={100}
              height={20}
              //   options={}
              data={data}
              // style={{ height: 300, width: "20%" }}
            />
          </div>
        </div>
      </div>
      <StatCardType
        title={"STORE STATUS"}
        type={"registration"}
        icon={require("../../../images/material-icons/shopBlack.png")}
        iconHeight={40}
        iconWidth={40}
        item1Title={"OPEN"}
        item1Value={storeStats ? storeStats.open : "loading"}
        item2Title={"OPENING SOON"}
        item2Value={
          storeStats
            ? Number(storeStats.openingSoon) -
              (Number(storeStats.open) +
                Number(storeStats.pending) +
                Number(storeStats.pipline))
            : "loading"
        }
        item3Title={"PENDING"}
        item3Value={storeStats ? storeStats.pending : "loading"}
        item4Title={"PIPLINE"}
        item4Value={storeStats ? storeStats.pipline : "loading"}
        marginTop={25}
        productStats={productStats}
      />
      <StatCardType
        marginTop={10}
        title={"PRODUCTS CATEGORIES"}
        type={"products"}
        icon={require("../../../images/material-icons/productBlack.png")}
        iconHeight={40}
        iconWidth={40}
        item1Title={"ALL PRODUCTS"}
        item1Value={productStats ? productStats.allproducts : "loading"}
        item2Title={"FSC FLOWER"}
        item2Value={productStats ? productStats.flower : "loading"}
        item3Title={"INFUSED BEVERAGES"}
        item3Value={productStats ? productStats.beverages : "loading"}
        item4Title={"INFUSED EDIBLES"}
        item4Value={productStats ? productStats.edibles : "loading"}
        item5Title={"VAPE & REFILLS"}
        item5Value={productStats ? productStats.vapeRefill : "loading"}
        item6Title={"FSC PRE-ROLL"}
        item6Value={productStats ? productStats.preRoll : "loading"}
        productStats={productStats}
      />
      {/* <StatCardType
        productStats={productStats}
        marginTop={10}
        title={"SALE STATS"}
        type={"sales"}
        icon={require("../../../images/material-icons/posBlack.png")}
        iconHeight={40}
        iconWidth={40}
        item1Title={"ALL PRODUCTS"}
        item1Value={"R 54 456,90"}
        item2Title={"FSC FLOWER"}
        item2Value={"R 10 000,00"}
        item3Title={"INFUSED BEVERAGES"}
        item3Value={"R 10 000,00"}
        item4Title={"INFUSED EDIBLES"}
        item4Value={"R 10 000,00"}
        item5Title={"VAPE & REFILLS"}
        item5Value={"R 10 000,00"}
        item6Title={"FSC PRE-ROLL"}
        item6Value={"R 10 000,00"}
        title1={"TOTAL QUICK VIEW"}
      /> */}
      <StatCardType
        productStats={productStats}
        title={"STAFF BREAKDOWN"}
        type={"registration"}
        icon={require("../../../images/material-icons/shopBlack.png")}
        iconHeight={40}
        iconWidth={40}
        item1Title={"BUD TENDERS"}
        item1Value={staffStats ? staffStats.budTenders : "loading"}
        item2Title={"MANAGERS"}
        item2Value={staffStats ? staffStats.managers : "loading"}
        item3Title={"OWNERS"}
        item3Value={staffStats ? staffStats.owners : "loading"}
        item4Title={"SUPPORT/ADMIN"}
        item4Value={staffStats ? staffStats.supportAndAdmin : "loading"}
        marginTop={25}
      />
      {/* <StatCard /> */}
      <div
        style={{
          position: "fixed",
          top: -10,
          left: 350,
          zIndex: 900000000000,
        }}
      >
        <BaseButtons
          label={"REFRESH"}
          onClick={onRefreshClick}
          height={40}
          invert={true}
        />
      </div>
      <StatPopUp
        dataPoint={patientStats ? patientStats.stats : null}
        statType={statType ? statType : "loading"}
        display={statType}
        onCloseClick={() => setStatType(null)}
      />
      <div style={{ padding: 20 }} />
    </div>
  );
};

export default DashBoard;

import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import { TextInputField } from "../../../components/atoms";
import { LoadingPopUp, PatientCard } from "../../../components";
import TabMobileButton from "../../customer-portal/components/mobile-layout/components/tab-mobile-button/TabMobileButton";
import QuestionButton from "../../customer-portal/components/medical-history/components/button/QuestionButton";
import SearchBar from "../../canna-pos/components/patient-display/SearchBar";
import FilterPopUp from "../../canna-pos/components/patient-display/FilterPopUp";

const AdminCustomerTab = ({
  isDoc,
  isCustomer,
  updateHandler,
  setSelectedPatient,
  docCheck,
  setData,
  csvExport,
  width,
  selectedPatient,
  refresh,
  setRefresh,
}) => {
  const [customers, setCustomers] = useState(null);
  const [arrayDisplay, setArrayDisplay] = useState(false);
  const [filterOpen, setFliterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("NONE");
  const [search, setSearch] = useState("");
  // const [displayMore, setDisplayMore] = useState(false);
  const check = async () => {
    await fetch("https://merry-jane-api.onrender.com/user/adminGetAllCustomers")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setCustomers(resData);
        if (setData) {
          setData(customers);
        }
      });
  };
  const [patientSearch, setPatientSearch] = useState("");
  useEffect(() => {
    if (!customers) {
      check();
    }

    if (customers && !csvExport) {
      if (setData) {
        setData(customers);
      }
    }
  }, [customers, refresh]);
  return (
    <div style={{ width: "100%" }}>
      {/* <div
        style={{
          height: 80,
          width: "83%",
          backgroundColor: BlackAndWhite.secondary,
          position: "fixed",
          display: "flex",
          flexDirection: "row",
          zIndex: 0,
          borderBottomStyle: "solid",
          borderBottomColor: "#00000066",
          marginTop: 1,
        }}
      >
        <div style={{ width: 400, heght: "100%" }}>
          <TextInputField
            labelTitle={"SEARCH PATIENT BY ID"}
            invert={true}
            size={320}
            onChange={(event) => setPatientSearch(event.target.value)}
          />
        </div>
        
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          <div style={{ width: 20 }} />
          You have {customers ? customers.users.length : 0} active patients.
         
          <div
            style={{
              height: 60,
              width: 60,
              borderRadius: 200,
              backgroundColor: BlackAndWhite.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: 20,
              // top: 10,
            }}
          >
            <img
              alt="icon"
              src={require("../../../images/material-icons/refreshwhite.png")}
              style={{ height: 40 }}
            />
          </div>
        </div>
      </div> */}

      {selectedFilter === "NONE" ? (
        customers ? (
          customers.users.map((customer, index) => {
            return (
              <div key={index}>
                <PatientCard
                  onClickClient={() => setSelectedPatient(customer)}
                  updateHandler={updateHandler}
                  isCustomer={isCustomer}
                  // isDoc={isDoc}
                  firstName={customer.firstName}
                  lastName={customer.lastName}
                  cellNumber={customer.cellNumber}
                  email={customer.email}
                  patientNo={customer.id}
                  idDoc={customer.idDoc}
                  dateOfBirth={customer.dateOfBirth}
                  gender={customer.gender}
                  town={customer.town}
                  suburb={customer.suburb}
                  type={customer.type}
                  dataId={customer.id}
                  docCheck={docCheck}
                />
              </div>
            );
          })
        ) : (
          <LoadingPopUp display={true} newWidth={"100%"} />
        )
      ) : null}
      {selectedFilter === "ID" ? (
        customers ? (
          customers.users.map((customer, index) => {
            return customer.idDoc.includes(search) ? (
              <div key={index}>
                <PatientCard
                  onClickClient={() => setSelectedPatient(customer)}
                  updateHandler={updateHandler}
                  isCustomer={isCustomer}
                  // isDoc={isDoc}
                  firstName={customer.firstName}
                  lastName={customer.lastName}
                  cellNumber={customer.cellNumber}
                  email={customer.email}
                  patientNo={customer.id}
                  idDoc={customer.idDoc}
                  dateOfBirth={customer.dateOfBirth}
                  gender={customer.gender}
                  town={customer.town}
                  suburb={customer.suburb}
                  type={customer.type}
                  dataId={customer.id}
                  docCheck={docCheck}
                />
              </div>
            ) : null;
          })
        ) : (
          <LoadingPopUp display={true} newWidth={"100%"} />
        )
      ) : null}

      {selectedFilter === "CELL" ? (
        customers ? (
          customers.users.map((customer, index) => {
            return customer.cellNumber.includes(search) ? (
              <div key={index}>
                <PatientCard
                  onClickClient={() => setSelectedPatient(customer)}
                  updateHandler={updateHandler}
                  isCustomer={isCustomer}
                  // isDoc={isDoc}
                  firstName={customer.firstName}
                  lastName={customer.lastName}
                  cellNumber={customer.cellNumber}
                  email={customer.email}
                  patientNo={customer.id}
                  idDoc={customer.idDoc}
                  dateOfBirth={customer.dateOfBirth}
                  gender={customer.gender}
                  town={customer.town}
                  suburb={customer.suburb}
                  type={customer.type}
                  dataId={customer.id}
                  docCheck={docCheck}
                />
              </div>
            ) : null;
          })
        ) : (
          <LoadingPopUp display={true} newWidth={"100%"} />
        )
      ) : null}

      {selectedFilter === "EMAIL" ? (
        customers ? (
          customers.users.map((customer, index) => {
            return customer.email.includes(search) ? (
              <div key={index}>
                <PatientCard
                  onClickClient={() => setSelectedPatient(customer)}
                  updateHandler={updateHandler}
                  isCustomer={isCustomer}
                  // isDoc={isDoc}
                  firstName={customer.firstName}
                  lastName={customer.lastName}
                  cellNumber={customer.cellNumber}
                  email={customer.email}
                  patientNo={customer.id}
                  idDoc={customer.idDoc}
                  dateOfBirth={customer.dateOfBirth}
                  gender={customer.gender}
                  town={customer.town}
                  suburb={customer.suburb}
                  type={customer.type}
                  dataId={customer.id}
                  docCheck={docCheck}
                />
              </div>
            ) : null;
          })
        ) : (
          <LoadingPopUp display={true} newWidth={"100%"} />
        )
      ) : null}
      {selectedFilter === "NAME" ? (
        customers ? (
          customers.users.map((customer, index) => {
            return customer.lastName.includes(search) ? (
              <div key={index}>
                <PatientCard
                  onClickClient={() => setSelectedPatient(customer)}
                  updateHandler={updateHandler}
                  isCustomer={isCustomer}
                  // isDoc={isDoc}
                  firstName={customer.firstName}
                  lastName={customer.lastName}
                  cellNumber={customer.cellNumber}
                  email={customer.email}
                  patientNo={customer.id}
                  idDoc={customer.idDoc}
                  dateOfBirth={customer.dateOfBirth}
                  gender={customer.gender}
                  town={customer.town}
                  suburb={customer.suburb}
                  type={customer.type}
                  dataId={customer.id}
                  docCheck={docCheck}
                />
              </div>
            ) : null;
          })
        ) : (
          <LoadingPopUp display={true} newWidth={"100%"} />
        )
      ) : null}
      {selectedPatient ? null : (
        <div
          style={{
            position: "fixed",
            top: 75,
            right: 15,
            zIndex: 400000000000,
          }}
        >
          {" "}
          <SearchBar
            width={width}
            onFilterClick={() => {
              setFliterOpen(filterOpen ? false : true);
            }}
            filterOption={selectedFilter}
            onChange={(e) => setSearch(e.target.value)}
          />{" "}
          <div style={{ position: "absolute", right: 40, marginTop: 10 }}>
            <FilterPopUp
              width={width}
              display={filterOpen}
              setFliterOpen={setFliterOpen}
              setSelectedFilter={setSelectedFilter}
              data={["ID", "CELL", "EMAIL", "NAME", "NONE"]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminCustomerTab;

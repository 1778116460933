import React, { useState } from "react";
import { InventoryProductCard } from "./components";

const StoresInventoryDisplayCard = ({
  data,
  storeName,
  productTotal,
  inStoreValue,
  type,
}) => {
  const [hover, setHover] = useState();
  const [drop, setDrop] = useState();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
      }}
    >
      <div
        onClick={() => setDrop(drop ? false : true)}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          width: "100%",
          height: 50,
          backgroundColor: hover
            ? drop
              ? "#Faa91980"
              : "#00000075"
            : drop
            ? "#FAA91970"
            : "#00000040",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "all 0.3s",
          fontWeight: hover ? "bold" : "normal",
        }}
      >
        <div style={{ marginLeft: 20, marginRight: 20 }}>{storeName}</div>
        <div style={{ flex: 1 }}></div>
        <div
          style={{
            fontSize: 14,
            marginRight: 20,
            display: "flex",
            fontWeight: "normal",
          }}
        >
          TYPE : <div style={{ marginLeft: 5 }}>{type}</div>
        </div>
        <div
          style={{
            fontSize: 14,
            marginRight: 20,
            display: "flex",
            fontWeight: "normal",
          }}
        >
          IN STORE VALUE : <div style={{ marginLeft: 5 }}>{inStoreValue}</div>
        </div>
        <div
          style={{
            fontSize: 14,
            marginRight: 20,
            display: "flex",
            fontWeight: "normal",
          }}
        >
          TOTAL PRODUCTS : <div style={{ marginLeft: 5 }}>{productTotal}</div>
        </div>
      </div>
      {drop
        ? data
          ? data.map((product, index) => {
              return (
                <div
                  style={{ width: "100%", transition: "all 0.3s" }}
                  key={index}
                >
                  <InventoryProductCard productName={product} />
                </div>
              );
            })
          : null
        : null}
    </div>
  );
};

export default StoresInventoryDisplayCard;

import React, { useEffect, useState } from "react";
import {
  BaseButtons,
  CheckInput,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import MeasurementSelector from "../measurment-selector/MeasurementSelector";
import { LoadingPopUp } from "../../../../../../components";
import CategorySelectorPopUp from "../list-view/components/category-select-pop-up/CategorySelectorPopUp";

const ListViewProductEdit = ({
  display,
  setSelectedProd,
  selectedProduct,
  setToastPop,
  setMessage,
  setLoading,
  loading,
  setNewLoad,
  newLoad,
  data,
}) => {
  const [areYouSure, setAreYouSure] = useState(false);
  const [retailPrice, setRetailPrice] = useState(0);
  const [retailPriceInc, setRetailPriceInc] = useState(0);
  const [costPrice, setCostPrice] = useState(0);
  const [merryJaneCostPrice, setMerryJaneCostPrice] = useState(0);
  const [mjCostBool, setMjCostBool] = useState(false);
  const [type, setType] = useState("");

  const [imageLink, setImageLink] = useState("");
  const [tag1, setTag1] = useState("");
  const [tag2, setTag2] = useState("");
  const [tag3, setTag3] = useState("");
  const [brands, setBrand] = useState("");
  const [supplier, setSupplier] = useState("");
  const [productName, setProductName] = useState("");
  const [salesMeasure, setSalesMeasure] = useState("");
  const [sku, setSku] = useState("");
  const [thcContent, setThcContent] = useState("");
  const [thcType, setThctype] = useState("");
  const [barcode, setBarcode] = useState("");
  const [measurement, setMeasurment] = useState("g");
  const [measurementOpen, setMeasuremntOpen] = useState(false);
  const [growType, setGrowType] = useState(null);
  const [gender, setGender] = useState("nothing");
  const [flavour, setFlavour] = useState("nothing");
  const [description, setDescription] = useState("");
  const [menuDescription, setMenuDescription] = useState("");
  const [useDescription, setUseDescription] = useState("");

  const [mjPercent, setMjPercent] = useState(0);
  const [costPercent, setCostPercent] = useState(0);
  const vatValue = 15;

  const valueCheckMerryJaneCost = (merryJaneCostPrice / 100) * mjPercent;
  const valueCheckCost = (costPrice / 100) * costPercent;
  const workOutVat = (retailPrice / 100) * vatValue;
  const workOutVaInclusive = (retailPriceInc / 100) * vatValue;
  const mjBasePriceDiff = costPrice - merryJaneCostPrice;
  const markUpBasePercent = (mjBasePriceDiff / merryJaneCostPrice) * 100;
  const mjBaseRetailDif = retailPrice - costPrice;
  const markRetailEarings = (mjBaseRetailDif / costPrice) * 100;

  const retailPercentMarkUpCheck = retailPriceInc - costPrice - workOutVat;
  const checkRetailInc = retailPercentMarkUpCheck;

  const [categoryOpen, setCategoryOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const [subcategorySelected, setSubcategorySelected] = useState(null);
  const [productIngredients, setProductIngredients] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [moreInfo, setMoreInfo] = useState("");

  const UpDateInfoHandler = (e) => {
    // if (costPrice !== merryJaneCostPrice) {
    //   setCostPrice(merryJaneCostPrice);
    // }

    const vatValueCheck = Number(e.target.value / 100);
    const checkForVatOnCost = Number(vatValueCheck * 15);
    const updateVatValue = Number(e.target.value - checkForVatOnCost);
    setMerryJaneCostPrice(Number(e.target.value));
    setCostPrice(Number(e.target.value).toFixed(2));
    setRetailPrice(Number(e.target.value).toFixed(2));
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
    // let checkValue = Number(costPrice);
  };

  const UpdateRetailPricePlusVat = (e) => {
    setRetailPrice(Number(e.target.value));
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
  };

  const RetailExVatHandler = (e) => {
    setRetailPriceInc(Number(e.target.value));
    const vatRemove = workOutVat;
    const checkRetailEx = (Number(e.target.value) / 1.15).toFixed(2);

    setRetailPrice(checkRetailEx);
  };

  const WholeSaleHandler = (e) => {
    setCostPrice(Number(e.target.value));
    setRetailPrice(Number(e.target.value).toFixed(2));
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
  };

  const ClearData = () => {
    setImageLink("");
    setProductName("");
    setSalesMeasure("");
    setMeasurment("");
    setSku("");
    setBarcode("");
    setTag1("");
    setTag2("");
    setTag3("");
    setThcContent("");
    setThctype("");
    setFlavour("");
    setGender("");
    setType("");
    setDescription("");
    setMenuDescription("");
    setUseDescription("");
    setGrowType("");
    setMerryJaneCostPrice(0);
    setCostPrice(0);
    setRetailPrice(0);
    setRetailPriceInc(0);
    setCategorySelected(null);
    setSubcategorySelected(null);
    setProductIngredients(null);
    setMoreInfo("");
  };

  let checkSubCategory = () => {
    if (selectedProduct) {
      return selectedProduct.productSubType;
    } else {
      return "";
    }
  };
  let flavourCheck = () => {
    if (flavour !== "None") {
      return (
        productName +
        " | " +
        checkSubCategory() +
        " | " +
        thcType +
        " | " +
        thcContent +
        " | " +
        flavour
      );
    } else {
      return (
        productName +
        " | " +
        checkSubCategory() +
        " | " +
        thcType +
        " | " +
        thcContent
      );
    }
  };

  let displayedName = flavourCheck();

  let productIngredientDescription = {
    productId: "description",
    value: productIngredients,
  };
  const upDateProduct = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/productLine/updateProducts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // step1
            productId: selectedProduct._id,
            productName: productName
              ? productName
              : selectedProduct.productName, //done
            productType: categorySelected
              ? categorySelected.categoryName
              : selectedProduct.productType, //done
            type: type ? type : "Na", //done
            size: salesMeasure ? salesMeasure : selectedProduct.size, //done called measure
            strain: thcType ? thcType : "Na", // done
            growType: growType ? growType : "Na", //done
            thcContent: thcContent || "Na", //done
            thcType: thcType || "Na", //done
            // productNo: productNo || "random genearted test", // want to aut generate product number just need varibles that shiuld be used from john
            quantityDenomiation: measurement, //done
            gender: gender || "Na", // done
            flavour: flavour || "Na", //done
            // step 2
            description: description,
            menuDescription: menuDescription,
            useDescription: useDescription,
            moreInfo: moreInfo ? moreInfo : selectedProduct.moreInfo,
            medicalUses: selectedProduct.medicalUses || [{ use: "none" }],
            productSubType: subcategorySelected
              ? subcategorySelected.subCategoryName
              : selectedProduct.subCategoryName,

            grouping: "none",
            tag1: tag1,
            tag2: tag2 ? tag2 : "nothing",
            tag3: tag3 ? tag3 : "nothing",
            // step 3
            categoryId: categorySelected
              ? categorySelected.categoryId
              : selectedProduct.categoryId,
            subCategoryId: subcategorySelected
              ? subcategorySelected.subCategoryId
              : selectedProduct.subCategoryId,
            baseCostPrice: merryJaneCostPrice, //done
            retailPrice: retailPrice, //done
            retailPriceInc: retailPriceInc, //done
            costPrice: costPrice, //done
            imageLink: imageLink,
            addedBy: selectedProduct.addedBy,
            // step4
            trackStock: "coming soon",
            sku: sku || "none",
            barcode: barcode || "none",
            ingredientDescription: {
              productId: "description",
              value: productIngredients || "none",
            },
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.updateProduct) {
        // const wait = await updateListFetch();
        setToastPop(true);
        setMessage("Product Uploaded");
        setLoading(false);
        setNewLoad(newLoad ? false : true);
        setSelectedProd(null);
        ClearData();
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  const deleteProduct = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/productLine/deleteProduct",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // step1
            productId: selectedProduct._id,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.message) {
        // const wait = await updateListFetch();
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
        setNewLoad(newLoad ? false : true);
        setSelectedProd(null);
        ClearData();
        setAreYouSure(false);
      }
    } catch (err) {
      console.log("error", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  const uploadProduct = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/createProduct",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // step1
            productName: selectedProduct.productName + " " + "-" + "Copy", //done
            productType: selectedProduct.productType, //done
            type: selectedProduct ? selectedProduct.type : "Na", //done
            size: salesMeasure, //done called measure
            strain: thcType ? thcType : "Na", // done
            growType: growType ? growType : "Na", //done
            thcContent: thcContent || "Na", //done
            thcType: thcType || "Na", //done
            // productNo: productNo || "random genearted test", // want to aut generate product number just need varibles that shiuld be used from john
            quantityDenomiation: measurement, //done
            gender: gender || "Na", // done
            flavour: flavour || "Na", //done
            // step 2
            description: description,
            menuDescription: menuDescription,
            useDescription: useDescription,
            moreInfo: selectedProduct.moreInfo,
            medicalUses: selectedProduct
              ? selectedProduct.medicalUses
              : [{ use: "none" }],
            productSubType: selectedProduct.productSubType,
            grouping: "none",
            tag1: tag1,
            tag2: tag2 ? tag2 : "nothing",
            tag3: tag3 ? tag3 : "nothing",
            // step 3
            categoryId: selectedProduct.categoryId,
            subCategoryId: selectedProduct.subCategoryId,
            baseCostPrice: merryJaneCostPrice, //done
            retailPrice: retailPrice, //done
            retailPriceInc: retailPriceInc, //done
            vat: selectedProduct.vat || "false", //done
            costPrice: costPrice, //done
            image: imageLink,
            addedBy: selectedProduct ? selectedProduct.addedBy : "admin",
            inventory: selectedProduct.inventory || "0",
            // step4
            onlineStore: selectedProduct.onlineStore,
            availableAt: selectedProduct.availableAt || "all",
            trackStock: "coming soon",
            sku: sku || "none",
            barcode: barcode || "none",
            upsaleQuestions: selectedProduct.upsaleQuestions || [],
            supplier: supplier || "Merry-Jane",
            brands: brands || "Merry-Jane",
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.newProduct) {
        // const wait = await updateListFetch();
        setToastPop(true);
        setMessage("Product copied and uploaded");
        setLoading(false);
        setNewLoad(newLoad ? false : true);
        setSelectedProd(null);
        ClearData();
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("error", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedProduct) {
      setImageLink(selectedProduct.imageUrl);
      setProductName(selectedProduct.productName);
      setSalesMeasure(selectedProduct.size);
      setMeasurment(selectedProduct.quantityDenomiation);
      setSku(selectedProduct.sku);
      setBarcode(selectedProduct.barcode);
      setTag1(selectedProduct.tag1);
      setTag2(selectedProduct.tag2);
      setTag3(selectedProduct.tag3);
      setThcContent(selectedProduct.thcContent);
      setThctype(selectedProduct.thcType);
      setFlavour(selectedProduct.flavour);
      setGender(selectedProduct.gender);
      setType(selectedProduct.type);
      setDescription(selectedProduct.description);
      setMenuDescription(selectedProduct.menuDescription);
      setUseDescription(selectedProduct.useDescription);
      setGrowType(selectedProduct.growType);
      setMerryJaneCostPrice(Number(selectedProduct.baseCostPrice).toFixed(2));
      setCostPrice(Number(selectedProduct.costPrice).toFixed(2));
      setRetailPrice(Number(selectedProduct.retailPrice).toFixed(2));
      setRetailPriceInc(Number(selectedProduct.retailPriceInc).toFixed(2));
      setBrand(selectedProduct.brands);
      setSupplier(selectedProduct.supplier);
      setMoreInfo(selectedProduct.moreInfo);
      if (selectedProduct.ingredients.length >= 1) {
        setProductIngredients(selectedProduct.ingredients[0].value);
      } else {
        setProductIngredients("");
      }
    }
  }, [selectedProduct]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [newLoad, selectedProduct]);
  return (
    <div
      style={{
        width: "100%",
        height: display ? "100%" : 0,
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "absolute",
        top: 0,
        transition: "all 0.2s",
        opacity: display ? 1 : 0,
      }}
    >
      <div style={{ position: "absolute", left: 20, top: 20 }}>
        <CloseButton
          label={"X"}
          onClick={() => {
            setSelectedProd(null);
            ClearData();
          }}
          invert={true}
        />
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          overflow: "scroll",
          backgroundColor: "#00000010",
          display: "flex",
        }}
      >
        <div style={{ flex: 0.7, display: "flex", flexDirection: "column" }}>
          {/* //product type section */}
          <div
            style={{
              minHeight: 200,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 30,
              marginBottom: 0,
            }}
          >
            <div
              style={{
                height: "95%",
                width: "95%",
                backgroundColor: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ width: "100%", textAlign: "left" }}>
                {" "}
                <div
                  style={{
                    marginLeft: 40,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: BlackAndWhite.primary,
                  }}
                >
                  Product type:
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: 20,
                  marginRight: 50,
                }}
              >
                <CheckInput
                  question={"Simple"}
                  check={type === "simple" ? true : false}
                  invert={true}
                  size={250}
                  onClick={() => setType("simple")}
                />
                <CheckInput
                  question={"Simple with variants"}
                  check={type === "simplewithvariants" ? true : false}
                  invert={true}
                  size={250}
                  onClick={() => setType("simplewithvariants")}
                />
                <CheckInput
                  question={"Composite"}
                  check={type === "composite" ? true : false}
                  invert={true}
                  size={250}
                  onClick={() => setType("composite")}
                />
              </div>
            </div>
          </div>
          {/* the primary info section */}
          <div
            style={{
              minHeight: 860,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: "95%",
                width: "95%",
                backgroundColor: BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginLeft: 40,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 0,
                  marginTop: 30,
                }}
              >
                PRIMARY INFORMATION:
              </div>
              <div
                style={{
                  flex: 1,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ height: 20 }} />
                <TextInputField
                  labelTitle={"POS Display Name (Auto Generated)"}
                  invert={true}
                  size={"80%"}
                  mini={true}
                  value={displayedName}
                  readOnly={true}
                  width={"100%"}
                />
                <div style={{ height: 10 }} />
                <TextInputField
                  mini={true}
                  labelTitle={"Product Name ( Required )"}
                  invert={true}
                  width={"100%"}
                  size={"80%"}
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    mini={true}
                    labelTitle={"Unit Size | Diemensions ( Required )"}
                    invert={true}
                    width={"50%"}
                    size={"60%"}
                    value={salesMeasure}
                    onChange={(e) => setSalesMeasure(e.target.value)}
                  />
                  <div style={{ width: 50 }} />
                  <div style={{ width: "40%", marginRight: 40 }}>
                    <MeasurementSelector
                      mini={true}
                      open={measurementOpen}
                      setType={setMeasurment}
                      type={measurement}
                      setTypeOpen={setMeasuremntOpen}
                      onTypeClick={() => setMeasuremntOpen(true)}
                    />
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex", marginTop: 10 }}>
                  <TextInputField
                    mini={true}
                    labelTitle={"SKU ( Required )"}
                    invert={true}
                    width={"50%"}
                    size={"60%"}
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                  />
                  <TextInputField
                    mini={true}
                    labelTitle={"Barcode"}
                    invert={true}
                    width={"50%"}
                    size={"60%"}
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                  />
                </div>
                <div style={{ width: "100%", display: "flex", marginTop: 10 }}>
                  <TextInputField
                    mini={true}
                    labelTitle={"Strength | Potency | Indicator"}
                    invert={true}
                    width={"50%"}
                    size={"60%"}
                    value={thcContent}
                    onChange={(e) => setThcContent(e.target.value)}
                  />
                  <TextInputField
                    mini={true}
                    labelTitle={"Product Feature"}
                    invert={true}
                    width={"50%"}
                    size={"60%"}
                    value={thcType}
                    onChange={(e) => setThctype(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <TextInputField
                    labelTitle={"Product Preference"}
                    invert={true}
                    size={"80%"}
                    mini={true}
                    value={flavour}
                    onChange={(e) => setFlavour(e.target.value)}
                    width={"100%"}
                  />

                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      // marginRight: 80,
                    }}
                  >
                    <div>Select Gender</div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-evenly",
                        marginTop: 5,
                      }}
                    >
                      <CheckInput
                        mini={true}
                        question={"Male"}
                        invert={true}
                        size={120}
                        marginRight={5}
                        check={gender === "male" ? true : false}
                        onClick={() => setGender("male")}
                      />
                      <CheckInput
                        mini={true}
                        question={"Female"}
                        invert={true}
                        size={120}
                        marginRight={5}
                        check={gender === "female" ? true : false}
                        onClick={() => setGender("female")}
                      />
                      <CheckInput
                        mini={true}
                        question={"N/A"}
                        invert={true}
                        size={120}
                        marginRight={5}
                        check={gender === "n/a" ? true : false}
                        onClick={() => setGender("n/a")}
                      />
                    </div>
                  </div> */}
                </div>
                {/* grow conditioons */}
                <div
                  style={{ width: "100%", textAlign: "left", marginTop: 35 }}
                >
                  {" "}
                  <div
                    style={{
                      marginLeft: 60,
                      fontSize: 14,
                      fontWeight: "bold",
                      color: BlackAndWhite.primary,
                      marginTop: 20,
                    }}
                  >
                    Grow condition:
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "90%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div style={{ width: 80 }} /> */}
                  <CheckInput
                    check={growType === "indoor" ? true : false}
                    question={"Indoor"}
                    invert={true}
                    size={150}
                    onClick={() => setGrowType("indoor")}
                  />
                  <CheckInput
                    check={growType === "greenhouse" ? true : false}
                    question={"Greenhouse"}
                    invert={true}
                    size={150}
                    onClick={() => setGrowType("greenhouse")}
                  />
                  <CheckInput
                    check={growType === "outdoor" ? true : false}
                    question={"Outdoor"}
                    invert={true}
                    size={150}
                    onClick={() => setGrowType("outdoor")}
                  />
                  <CheckInput
                    check={growType === "n/a" ? true : false}
                    question={"N/A"}
                    invert={true}
                    size={150}
                    onClick={() => setGrowType("n/a")}
                  />
                </div>
              </div>
              {/* image and tags */}
            </div>
          </div>
          {/* //descriptions section */}
          <div
            style={{
              minHeight: 900,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 5,
              // marginBottom: 60,
            }}
          >
            <div
              style={{
                height: "95%",
                width: "95%",
                backgroundColor: BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ width: "100%", textAlign: "left", marginBottom: 20 }}
              >
                {" "}
                <div
                  style={{
                    marginLeft: 40,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: BlackAndWhite.primary,
                  }}
                >
                  Descriptions:
                </div>
              </div>
              <TextAreaInput
                size={"91%"}
                width={"100%"}
                mini={true}
                labelTitle={"Full Product Description"}
                color={BlackAndWhite.primary}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
              <TextAreaInput
                size={"91%"}
                width={"100%"}
                mini={true}
                labelTitle={"Short Product description ( max 150 characters )"}
                color={BlackAndWhite.primary}
                onChange={(e) => setMenuDescription(e.target.value)}
                value={menuDescription}
              />
              <TextAreaInput
                size={"91%"}
                width={"100%"}
                mini={true}
                labelTitle={"Product Details"}
                color={BlackAndWhite.primary}
                onChange={(e) => setMoreInfo(e.target.value)}
                value={moreInfo}
              />
              <TextAreaInput
                size={"91%"}
                width={"100%"}
                mini={true}
                labelTitle={"Product Ingredients"}
                color={BlackAndWhite.primary}
                onChange={(e) => setProductIngredients(e.target.value)}
                value={productIngredients}
              />
              <TextAreaInput
                size={"91%"}
                width={"100%"}
                mini={true}
                labelTitle={"Product Usage"}
                color={BlackAndWhite.primary}
                onChange={(e) => setUseDescription(e.target.value)}
                value={useDescription}
              />
            </div>
          </div>
          {/* Pricing */}
          <div
            style={{
              minHeight: 250,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "95%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                // marginTop: 10,
                height: "85%",
                backgroundColor: BlackAndWhite.secondary,
                // WebkitBoxShadow: "0px 0px 5px 1px #00000030",
              }}
            >
              <div style={{ width: "100%", textAlign: "left" }}>
                {" "}
                <div
                  style={{
                    marginLeft: 40,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: BlackAndWhite.primary,
                  }}
                >
                  COSTING / PRINCING
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 80,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <TextInputField
                  labelTitle={"Cost Excl"}
                  invert
                  min={0}
                  size={100}
                  onChange={(e) => UpDateInfoHandler(e)}
                  value={merryJaneCostPrice}
                  mini={true}
                  height={20}
                  type={"number"}
                  enter={() =>
                    setMerryJaneCostPrice(merryJaneCostPrice.toFixed(2))
                  }
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    min={0}
                    type={"number"}
                    height={20}
                    mini={true}
                    labelTitle={"W-sale Excl"}
                    // autoFocus={true}
                    invert
                    size={100}
                    onChange={(e) => {
                      WholeSaleHandler(e);
                    }}
                    value={costPrice}
                    amountValue={mjPercent}
                    amountMarkedUp={
                      "R" +
                      " " +
                      (
                        Number(valueCheckMerryJaneCost) + Number(costPrice)
                      ).toFixed(2)
                    }
                    enter={() => setCostPrice(costPrice.toFixed(2))}
                  />
                  <div
                    style={{
                      width: 60,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                      fontSize: 12,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{ fontSize: 14, marginBottom: 5, opacity: 0.5 }}
                    >
                      Mark up
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // borderStyle: "solid",
                        borderWidth: 0.5,
                        height: 37,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#00000030",
                      }}
                    >
                      <div>{"R" + " " + mjBasePriceDiff.toFixed(2)}</div>
                      <div>{markUpBasePercent.toFixed(2) + "%"}</div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    min={0}
                    type={"number"}
                    labelTitle={"Retail Excl"}
                    invert
                    size={100}
                    onChange={(e) => UpdateRetailPricePlusVat(e)}
                    value={retailPrice}
                    mini={true}
                    height={20}
                    enter={() => setRetailPrice(retailPrice.toFixed(2))}
                  />
                  <div
                    style={{
                      width: 60,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                      fontSize: 12,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{ fontSize: 14, marginBottom: 5, opacity: 0.5 }}
                    >
                      Mark up
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // borderStyle: "solid",
                        borderWidth: 0.5,
                        height: 37,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#00000030",
                      }}
                    >
                      <div>
                        {"R" + " " + Number(retailPrice - costPrice).toFixed(2)}
                      </div>
                      <div>{markRetailEarings.toFixed(2) + "%"}</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    min={0}
                    type={"number"}
                    labelTitle={"Retail Inc Vat"}
                    invert
                    size={100}
                    onChange={(e) => RetailExVatHandler(e)}
                    value={retailPriceInc}
                    mini={true}
                    height={20}
                    enter={() => setRetailPriceInc(retailPriceInc.toFixed(2))}
                  />
                  <div
                    style={{
                      width: 60,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                      fontSize: 12,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{ fontSize: 14, marginBottom: 5, opacity: 0.5 }}
                    >
                      VAT
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // borderStyle: "solid",
                        borderWidth: 0.5,
                        height: 37,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#00000030",
                      }}
                    >
                      <div>R {workOutVaInclusive.toFixed(2)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* //variants section */}
          {/* ingredients sectipns for composite producst */}
          {/* //submit changes or delete */}
          <div
            style={{
              minHeight: 150,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // marginTop: 10,
              marginBottom: 70,
            }}
          >
            <div
              style={{
                height: areYouSure ? 0 : "95%",
                width: areYouSure ? 0 : "95%",
                backgroundColor: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflow: "hidden",
                transition: "all 0.3s",
              }}
            >
              <BaseButtons
                label={"Update"}
                marginTopFalse={true}
                onClick={() => upDateProduct()}
              />
              <BaseButtons
                label={"Duplicate"}
                marginTopFalse={true}
                disable={selectedProduct ? false : true}
                onClick={() => uploadProduct()}
              />
              <BaseButtons
                label={"Delete"}
                marginTopFalse={true}
                onClick={() => setAreYouSure(true)}
              />
            </div>
            <div
              style={{
                height: areYouSure ? "95%" : 0,
                width: areYouSure ? "95%" : 0,
                backgroundColor: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflow: "hidden",
                transition: "all 0.3s",
              }}
            >
              <div>ARE YOU SURE YOU WANT TO DELETE THIS PRODUCT?</div>
              <BaseButtons
                label={"YES"}
                marginTopFalse={true}
                onClick={() => deleteProduct()}
              />
              <BaseButtons
                label={"NO"}
                marginTopFalse={true}
                onClick={() => setAreYouSure(false)}
              />
            </div>
          </div>
          <div style={{ padding: 80 }}></div>
        </div>
        {/* right section */}
        <div style={{ flex: 0.3 }}>
          {/* images and catorgires brands and supllier */}
          <div
            style={{
              height: 1070,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                height: "95%",
                width: "90%",
                backgroundColor: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ width: "100%", textAlign: "left" }}>
                <div
                  style={{
                    marginLeft: 20,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: BlackAndWhite.primary,
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  Product Images and categories:
                </div>
                <div
                  style={{
                    height: 200,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* //imagr displaeyed here */}
                  <div
                    style={{
                      minHeight: 200,
                      width: 200,
                    }}
                  >
                    <img
                      alt="icon"
                      src={imageLink}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <TextInputField
                    mini={true}
                    labelTitle={"Image Link:"}
                    invert={true}
                    width={"100%"}
                    size={"65%"}
                    value={imageLink}
                    onChange={(e) => setImageLink(e.target.value)}
                  />
                  <div
                    style={{
                      width: "100%",
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "65%",
                        textAlign: "left",
                        marginBottom: 20,
                      }}
                    >
                      Image edit descriotion on how to add new image or change
                    </div>
                  </div>
                  {/* display category and tags here */}
                  <div
                    style={{
                      width: 250,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginLeft: 10,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 10,
                        display: "flex",
                        flexDirection: "column",
                        width: "90%",
                        // marginLeft: 10,
                      }}
                    >
                      <div
                        style={{ opacity: 0.5, marginBottom: 5, fontSize: 13 }}
                      >
                        Category :
                      </div>{" "}
                      {selectedProduct ? selectedProduct.productType : null}
                    </div>
                    <div
                      style={{
                        marginBottom: 10,
                        display: "flex",
                        flexDirection: "column",
                        width: "90%",
                      }}
                    >
                      <div
                        style={{ opacity: 0.5, marginBottom: 5, fontSize: 13 }}
                      >
                        Sub-Category :
                      </div>{" "}
                      {selectedProduct ? selectedProduct.productSubType : null}
                    </div>
                    <BaseButtons
                      label={
                        categorySelected
                          ? categorySelected.categoryName
                          : "Change Categories"
                      }
                      mini={true}
                      size={250}
                      height={subcategorySelected ? 30 : 50}
                      onClick={() => setCategoryOpen(true)}
                      marginTopFalse={true}
                    />
                    {subcategorySelected ? (
                      <div
                        style={{
                          backgroundColor: "#FAA919",
                          width: "100%",
                          textAlign: "center",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        {subcategorySelected.subCategoryName}
                      </div>
                    ) : null}
                    <TextInputField
                      mini={true}
                      labelTitle={"Brand:"}
                      invert={true}
                      width={"100%"}
                      size={"80%"}
                      value={brands}
                      onChange={(e) => setBrand(e.target.value)}
                      height={25}
                    />
                    <div style={{ height: 10 }} />
                    <TextInputField
                      mini={true}
                      labelTitle={"Supplier:"}
                      invert={true}
                      width={"100%"}
                      size={"80%"}
                      value={supplier}
                      onChange={(e) => setSupplier(e.target.value)}
                      height={25}
                    />
                  </div>

                  <div
                    style={{
                      width: 250,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextInputField
                      mini={true}
                      labelTitle={"Tag 1:"}
                      invert={true}
                      width={"100%"}
                      size={"80%"}
                      value={tag1}
                      onChange={(e) => setTag1(e.target.value)}
                      height={25}
                    />
                    <div style={{ height: 10 }} />
                    <TextInputField
                      mini={true}
                      labelTitle={"Tag 2:"}
                      invert={true}
                      width={"100%"}
                      size={"80%"}
                      value={tag2}
                      onChange={(e) => setTag2(e.target.value)}
                      height={25}
                    />
                    <div style={{ height: 10 }} />
                    <TextInputField
                      mini={true}
                      labelTitle={"Tag 3:"}
                      invert={true}
                      width={"100%"}
                      size={"80%"}
                      value={tag3}
                      onChange={(e) => setTag3(e.target.value)}
                      height={25}
                    />
                  </div>
                  {/* //primary section */}
                </div>
              </div>
            </div>
          </div>

          {/* //medical uses section */}
          <div
            style={{
              height: 200,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                height: "95%",
                width: "90%",
                backgroundColor: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  minHeight: 250,
                  width: "99%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    // marginTop: 10,
                    height: "85%",
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    {" "}
                    <div
                      style={{
                        marginLeft: 40,
                        fontSize: 18,
                        fontWeight: "bold",
                        color: BlackAndWhite.primary,
                      }}
                    >
                      Medical Uses:
                    </div>
                  </div>
                  <div
                    style={{ width: "90%", display: "flex", flexWrap: "wrap" }}
                  >
                    {selectedProduct
                      ? selectedProduct.medicalUses.length >= 1
                        ? selectedProduct.medicalUses.map((use, index) => {
                            return (
                              <div key={index} style={{ margin: 15 }}>
                                {use.use}
                              </div>
                            );
                          })
                        : "No uses added"
                      : "loading"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* //upsale questions uses section */}
          <div
            style={{
              height: 200,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                height: "95%",
                width: "90%",
                backgroundColor: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  minHeight: 250,
                  width: "99%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    // marginTop: 10,
                    height: "85%",
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    {" "}
                    <div
                      style={{
                        marginLeft: 40,
                        fontSize: 18,
                        fontWeight: "bold",
                        color: BlackAndWhite.primary,
                        marginBottom: 40,
                      }}
                    >
                      Upsale Questions:
                    </div>
                  </div>
                  <div
                    style={{ width: "90%", display: "flex", flexWrap: "wrap" }}
                  >
                    {selectedProduct
                      ? selectedProduct.upsaleQuestions.length >= 1
                        ? selectedProduct.upsaleQuestions.map(
                            (question, index) => {
                              return (
                                <div key={index} style={{ margin: 15 }}>
                                  {question.question}
                                </div>
                              );
                            }
                          )
                        : "No questions added"
                      : "loading"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CategorySelectorPopUp
        display={categoryOpen}
        categoryArray={data}
        onCloseClick={() => setCategoryOpen(false)}
        setCategorySelected={setCategorySelected}
        setCategoryOpen={setCategoryOpen}
        setSubcategorySelected={setSubcategorySelected}
        categorySelected={categorySelected}
        setToastPop={setToastPop}
        setMessage={setMessage}
        selectedSubCategory={subcategorySelected}
      />
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default ListViewProductEdit;

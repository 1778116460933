import React, { useState, useEffect } from "react";
import PosPatientCard from "./PosPatientCard";
import { LoadingPopUp, NewSearchBar } from "../../../../components";
import PatientProfilePopUp from "./PatientProfilePopUp";
import SearchBar from "./SearchBar";
import FilterPopUp from "./FilterPopUp";

const PatientDisplay = ({
  setToastPop,
  setMessage,
  setPatientProfilePop,
  setSelectedPatient,
  setLoading,
  width,
  search,
  setSearch,
  refresh,
  setRefresh,
  loading,
  customers,
}) => {
  const [arrayDisplay, setArrayDisplay] = useState(false);

  const [filterOpen, setFliterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("NONE");

  // const [displayMore, setDisplayMore] = useState(false);

  const [patientSearch, setPatientSearch] = useState("");

  return (
    <div
      style={{
        flex: 1,
        overflowY: "scroll",
      }}
    >
      {selectedFilter === "NONE" ? (
        customers ? (
          customers.map((patient, index) => {
            return (
              <div key={index}>
                <PosPatientCard
                  firstName={patient ? patient.firstName : "loading"}
                  lastName={patient ? patient.lastName : "loading"}
                  idNum={patient ? patient.idDoc : "loading"}
                  email={patient ? patient.email : "loading"}
                  status={"in progress"}
                  cell={patient ? patient.cellNumber : "loading"}
                  onClick={() => {
                    setPatientProfilePop(true);
                    setSelectedPatient(patient);
                    setLoading(true);
                  }}
                />
              </div>
            );
          })
        ) : (
          <div style={{ width: "100%", textAlign: "center", marginTop: 40 }}>
            SEARCH FOR USER ABOVE ... WHEN YOUR READY
          </div>
        )
      ) : null}

      <div
        style={{
          position: "fixed",
          top: 75,
          right: 15,
          zIndex: 400000,
        }}
      ></div>
    </div>
  );
};

export default PatientDisplay;

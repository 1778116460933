import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const FacilityItem = ({
  storeName,
  province,
  type,
  city,
  email,
  cell,
  onManageClick,
  onDeleteClick,
  owner,
}) => {
  const [hover, setHover] = useState(false);
  const [manageHover, setManageHover] = useState(false);
  const [dleeteHover, setDeleteHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "100%",
        height: 45,
        // borderStyle: "solid",
        display: "flex",
        fontSize: 12,
        backgroundColor: hover ? "#9E3AC350" : BlackAndWhite.secondary,
        cursor: "pointer",
        position: "relative",
        transition: "all 0.3s",
      }}
    >
      <div
        style={{
          flex: 0.3,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ marginLeft: 10 }}>{storeName}</div>
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>{province}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>{type}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>{city}</div>
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>{email}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>{cell}</div>
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>{owner}</div>
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={onManageClick}
          onMouseOver={() => setManageHover(true)}
          onMouseOut={() => setManageHover(false)}
          style={{
            backgroundColor: manageHover
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
            borderRadius: 4,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            color: manageHover
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
            cursor: "pointer",
          }}
        >
          Manage
        </div>
        <div style={{ width: 20 }} />
      </div>
      <div
        style={{
          height: 1.5,
          width: "100%",
          backgroundColor: BlackAndWhite.primary,
          position: "absolute",
          bottom: 0,
          opacity: 0.5,
        }}
      />
    </div>
  );
};

export default FacilityItem;

import React, { useEffect, useState } from "react";
import { Title } from "../components";
import { DropDownWithButton } from "../../../../../../../../../../components";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../../../../../components/atoms";

const SocialMemberTerms = ({
  isSigned,
  dateCreated,
  signedAtAlready,
  userName,
  signedAt,
  setSignedAt,
  onAcceptClick,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 18,
          marginTop: 18,
        }}
      >
        <div style={{ marginLeft: 20 }}> TERM'S AND CONDITIONS</div>
      </div>
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
          textAlign: "center",
        }}
      >
        SOCIAL CLUB MEMBERSHIP AGREEMENT
      </div>
      <div style={{ width: "80%", marginTop: 20, textAlign: "center" }}>
        entered into between
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        MERRY-JANE HOLDINGS (PTY) LTD T/A MERRY-JANE PRIVATE MEMBERS CLUB
      </div>
      <div style={{ width: "80%", marginTop: 10, textAlign: "center" }}>
        and
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        THE MEMBER
      </div>

      <div
        style={{
          width: "80%",
          marginTop: 40,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        WHEREAS Merry-Jane Holdings (Pty) Ltd. t/a Merry-Jane Private Members
        Club (“Merry-Jane”) operates a private members-only social club which
        charges fees for use of its private premises, facilitates the
        collective, consensual and private sharing of cannabis among its members
        for their personal use without the exchange of consideration between
        each other or Merry-Jane, by mandate administers the collection and in
        some cases pooling of Members’ funds to cover the cultivation costs of
        Members’ private cannabis, as incurred by Professional Horticultural
        Services Provider members of Merry-Jane, and administers the storage and
        collection of each member’s private cannabis, for their personal,
        private consumption thereof (the “Services”);
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 20,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        WHEREAS the Member, as a private adult person, is desirous of appointing
        MerryJane to administer the private cultivation and costs thereof of
        crops owned by the Member for their own personal use in a private
        space/residence, as required for the purposes set out herein, because
        the Member does not possess the requisite knowledge, skill, space or
        infrastructure to grow the crop;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 20,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        WHEREAS the Member has and/or intends to through the Services of
        Merry-Jane and that of affiliated Professional Horticultural Services
        Providers, lease private land, not accessible to the public, as set out
        in a separate Professional Horticultural Services Agreement. In terms of
        this Agreement and the Professional Horticultural Services Agreement,
        the aforementioned specific land is assigned to the Member along with a
        designated private Professional Horticultural Services Provider member
        of Merry-Jane, who will cultivate the Member’s crop on their behalf for
        Members’ private, personal use, the cost of which shall be borne by the
        Member, and
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 20,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        WHEREAS the Professional Horticultural Services Member has represented
        that he/she is an adult and has the requisite skill and experience in
        rendering the horticultural expertise to cultivate the desired crop for
        the Member and has sufficient capacity and resources to perform the
        cultivation in accordance with the terms of this Agreement. The
        Professional Horticultural Services Provider member acknowledges that
        the Member has entered into this Agreement on reliance on through
        representation, and
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 20,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        WHEREAS neither the Professional Horticultural Services Provider Member
        nor the Member sells, trades in, deals in, distributes or commercialises
        the crop, such that the Professional Horticultural Services Provider
        Member offers only their skill and experience in cultivating the
        Member’s crop. The Member at all times remains the lawful owner of their
        personal, private crop and bears the cost of cultivation thereof, which
        costs shall be collected and administered by Merry-Jane.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 20,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        Accordingly, the Member and the Professional Horticultural Services
        Provider, enter into this Agreement on and with effect from the
        Commencement Date, on the terms and subject to the conditions recorded
        herein and subject to ANNEXURES A to C to this Agreement, and both the
        Member and the Professional Horticultural Services Provider acknowledge
        that this Agreement is binding and the services provided herein are only
        permissible and valid when rendered through Merry-Jane to the Members of
        Merry-Jane and no other entity, individual or company providing the same
        or similar services. The Member and Professional Horticultural Services{" "}
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 20,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        Provider further acknowledge that they will be held liable by Merry-Jane
        for damages suffered (liquidated and/or otherwise) due to any breaches
        of this Agreement.
      </div>
      <Title number={"1."} title={"REGISTRATION"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        1.1. To be able to register successfully as a Member, the Member agrees
        to provide Merry-Jane with relevant information and personal details
        filled in on Merry-Jane membership form on Merry-Jane’ web-based data
        collection mechanism (the “Application”). Upon approval and validation
        of these details, Merry-Jane will provide the Member with a unique
        username and password (“Credentials”) to access service-related
        offerings via the members` portal on the Application.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        1.2. By registering to Merry-Jane, the Member will become a private
        member of Merry-Jane.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        1.3. By registering to Merry-Jane, the Member will be given use right of
        the online application / data collection mechanisms of Merry-Jane.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        1.4. By registering to Merry-Jane, the Member will gain access to
        private facilities of Merry-Jane.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        1.5. By registering to Merry-Jane, the Member will gain access to the
        private holding facilities of Merry-Jane for the product owned by the
        Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        1.6. By registering on the Application and/or accessing the Application
        and/or using the Application, the Member hereby agrees to be legally
        bound by this Agreement, regardless of whether the Member has expressly
        signed or assented to the terms and conditions contained in this
        Agreement, and in particularly has provided a valid identity document
        proving compliance with the minimum age for Members of 18 years.
      </div>
      <Title number={"2."} title={"CLUB MEMBERSHIP SERVICES"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.1. The Professional Horticultural Services Provider Member shall
        provide the Services to the Member as more fully set out herein, which
        Services the Member accepts in exchange for the Membership Fee.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.2. The Parties have agreed to the terms and conditions upon which the
        Professional Horticultural Services Provider Member shall render
        professional horticultural services to the Member for which the Member
        shall pay and which payment shall be collected and administered by
        Merry-Jane in a manner of MerryJane’s choosing.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.3. The Member warrants that the services of the Professional
        Horticultural Services Provider Member of Merry-Jane have been procured
        solely for the cultivation of the Member’s crops for the Member’s own
        personal use and the Member represents and warrants that he/she in no
        way intends to sell, deal in, trade, distribute or commercialise the
        harvest of Cannabis in contravention of any Laws of the Republic of
        South Africa.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.4. The Professional Horticultural Services Provider Member warrants
        that the services rendered to the Member in terms of the Professional
        Horticultural Services Provider Agreement and this agreement are solely
        for the cultivation of the Member’s crop for the Member’s private,
        personal use and that both the Professional Horticultural Services
        Provider and Member warrants that he/she in no way intends to use, sell,
        deal in, trade, distribute or commercialise the Member’s harvest of
        Cannabis in contravention of any Laws of the Republic of South Africa.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.5. In the event that the Member contravenes the provisions contained
        in clause 2.1 – 2.4, the Member indemnifies the Professional
        Horticultural Services Provider and Merry-Jane against all legal
        liability, damages and/or claims, of a civil and/or criminal nature,
        arising from the Member’s contravention of the provisions contained in
        clause 2.1 – 2.4, to the extent that the Professional Horticultural
        Services Provider and Merry-Jane are not found to be involved in the
        contravention under reference.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.6. In the event that the Professional Horticultural Services Provider
        contravenes the provisions contained in clause 2.1 – 2.4, the
        Professional Horticultural Services Provider indemnifies the Member and
        Merry-Jane against all damages and/or claims, of a civil and/or criminal
        nature, arising from the Professional Horticultural Services Provider’s
        contravention of the provisions contained in clause 2.1 – 2.4, to the
        extent that the Member and Merry-Jane are not found to be involved in
        the contravention under reference.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.7. The Member’s membership and Services will be terminated with
        immediate effect in the event that the Member contravenes the provisions
        contained in clause 2.1 – 2.4.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.8. The membership model offered to the Member by Merry-Jane and the
        related professional horticultural services rendered by the Professional
        Horticultural Services Provider are in accordance with an interpretation
        of the Right to Privacy established by the Constitutional Court in the
        seminal case of Minister of Justice and Constitutional Development &
        Others v Prince (Clarke and Others Intervening); National Director of
        Public Prosecutions and Others v Rubin; National Director of Public
        Prosecutions and Others v Acton [2018] ZACC 30 (the "Judgment") and the
        Cannabis for Private Purposes Act (the “CFPPA”), which has recently been
        passed by the National Assembly, and regulates the private consumption
        of cannabis in South Africa such that no adult person may exchange
        compensation, gift, benefit, reward or favour for cannabis. Accordingly,
        the Member has agreed to contract with Merry-Jane for the administration
        of Professional Horticultural Services in relation to the Member’s own
        cannabis. Notwithstanding this, the legal framework upon which the
        Private Members Club is predicated, may therefore be subject to change
        as new legislation is drafted and enacted. The provisions of this
        Agreement are therefore also subject to change in accordance with the
        changing law.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.9. Within a period of three months the Member will have an amount of
        their cannabis crop available to them through the Services contemplated
        in this Agreement, cultivated by the Professional Horticultural Services
        Provider Member and securely stored by Merry-Jane and may agree to share
        said cannabis with other Members, without the exchange of consideration.
        In the meantime and in future, the Member may access other Members’
        private cannabis which has been consensually made available by said
        Members, to privately share with the Member in good faith as a member of
        Merry-Jane, and the Member agrees to contribute on a pro-rata basis
        towards the costs of cultivation of said cannabis so as to positively
        contribute towards the ongoing nature of Merry-Jane and the costs of the
        Professional Horticultural Services Provider Member’s input costs and
        services rendered, in good faith. This is done to promote all Members’
        access to their Right to Privacy and their personal, private cultivation
        and consumption of private cannabis by spreading the costs of
        cultivation among all Members of Merry-Jane who wish to share their
        private cannabis with other Members for their personal use and without
        the exchange of consideration.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.10. Harvest and Collection:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.10.1. The Member undertakes to personally collect their harvested,
        dried and cured crop after notification from Merry-Jane, which
        collection time must be prearranged with Merry-Jane. The Member will
        further be required to provide proof of identification upon collection.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.10.2. The harvest of the Member’s Cannabis crop includes the curing,
        drying and storing of the harvest by the Professional Horticultural
        Services Provider Member and/or Merry-Jane in a diligent and a
        professional manner, in accordance with the accepted practices and high
        professional standards currently used in performing these services. The
        Professional Horticultural Services Provider will safely store the
        Cannabis harvest and ensure that the harvest is identifiable as
        belonging to the Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        2.10.3 Merry-Jane take receipt of and securely stored shall make
        available certain shelf space and/or storage capacity to retain Member’s
        harvested, dried and cured crops, including that which has been made
        available to share with other Members. Members will then collect their
        individual and/or shared crops in portion sizes of their choosing,
        within the reasonable bounds of personal, private consumption, and
        contribute towards the cost of Growing Services rendered on a pro-rata
        basis.
      </div>
      <Title number={"3."} title={"PRIVACY"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.1. Merry-Jane operates a Social Club and facilitates leasing and
        cultivation cost administration and sharing arrangements between Members
        on behalf of and on instruction of the Members, such that the Member
        leases a portion of land upon which the cannabis product is grown
        exclusively, by an adult Professional Horticultural Services Provider
        Member and, once grown and upon instruction of the Member, is
        transferred to the location of Merry-Jane, kept in a private holding
        space exclusively for the Member (to which only the Member and their
        nominated proxies have access), and provides a private space to the
        member and other Members to enjoy and share their own product within the
        compounds of Merry-Jane without the exchange of consideration.
        Merry-Jane charges a Membership Fee for the access to its private
        premises and the administration of the collective cultivation of its
        Members’ private cannabis.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.2. Merry-Jane shall take all reasonable measures to protect the
        Member’s privacy as more fully set out below.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.3. Upon registration, Merry-Jane may require the Member to provide
        personal information which includes but is not limited to, name,
        surname, email address, physical address, gender, mobile number and date
        of birth.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.4. Should the Member’s personal information change, he/she should
        inform MerryJane via reasonable notice and provide updates to the
        personal information as soon as reasonably possible to enable Merry-Jane
        to update the personal information.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.5. The Member may choose to provide additional personal information to
        MerryJane, in which event the Member agrees to provide accurate and
        current information, and not to impersonate or misrepresent any person
        or entity or falsely state or otherwise misrepresent an affiliation with
        anyone or anything.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6. Merry-Jane will not, without the Member’s express consent:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1. Use the Member’s personal information for any purpose other than
        as set out below:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1.1. in relation to the Services;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1.2. to contact the Member regarding current or new goods offered or
        any of Merry-Jane divisions, affiliates and/or partners (to the extent
        that the Member has not opted out from receiving marketing material from
        Merry-Jane);
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1.3. to inform the Member of new features, special offers and
        promotional competitions offered by Merry-Jane or any of its divisions,
        affiliates and/or partners (to the extent that the Member has not opted
        out from receiving marketing material from Merry-Jane); and
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1.4. disclose the Member’s personal information to any third party
        other than as set out below:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1.4.1. to Merry-Jane employees and/or third-party Professional
        Horticultural Services Provider Members who assist Merry-Jane to
        interact with the Member through email or any other method, for the
        requesting of the Services;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1.4.2. to Merry-Jane divisions, affiliates and/or partners
        (including their employees and/or third-party Professional Horticultural
        Services Provider Members) in order for them to interact directly with
        the Member by email or any other method for purposes of sending the
        Member marketing material regarding any current or new offers by them
        (unless the Member has opted out from receiving marketing material from
        Merry-Jane);
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1.4.3. to law enforcement, government officials, fraud detection
        agencies or other third parties when Merry-Jane believes in good faith
        that the disclosure of personal information is necessary to prevent
        physical harm or financial loss, to report or support the investigation
        into suspected illegal activity, or to investigate violations of this
        Agreement; and
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.6.1.4.4. to Professional Horticultural Services Provider Members who
        help with the parts of Merry-Jane business operations (fraud prevention,
        marketing, technology services, etc.).
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.7. Merry-Jane is entitled to use or disclose the Member’s personal
        information if such use or disclosure is required in order to comply
        with any applicable law, subpoena, order of court or legal process
        served on Merry-Jane, or to protect and defend Merry-Jane rights or
        property.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.8. Merry-Jane shall ensure that all of its employees, third party
        Professional Horticultural Services Provider Members, divisions,
        affiliates and partners (including their employees and third-party
        Professional Horticultural Services Provider Members) having access to
        the Member’s personal information are bound by appropriate and legally
        binding confidentiality obligations in relations to the Member’s
        personal information.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.9. Notwithstanding anything to the contrary, Merry-Jane shall not
        retain the Member’s personal information longer than the period for
        which it was originally needed, unless it is required by law to do so,
        or the Member consents to the retaining of such information for a longer
        period.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.10. The Member hereby warrants that it shall provide to Merry-Jane all
        the requested information as set out in the Information Sheet or on the
        Application.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.11. Given the sensitive nature of the information provided, Merry-Jane
        hereby warrants that:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.11.1. it has complied and shall remain to comply with all provisions
        of the Protection of Personal Information Act 4 of 2013;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.11.2. all relevant checks, tests, safeguards and procedures in terms
        of the aforesaid Act have been followed and adequately adhered to; and
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.11.3. all relevant permissions, waivers, authorisation, approvals and
        necessary consent has been obtained.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        3.12. The Member hereby indemnifies Merry-Jane against any and all
        claims that may arise as a result of a failure on the part of Merry-Jane
        to comply with any provision of the Protection of Personal Information
        Act 4 of 2013.
      </div>
      <Title number={"4."} title={"USE OF APPLICATION"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        4.1. The Application is a web-based, online, private portal that
        provides access to the information of Members of their private cannabis
        and cannabis by-products produced and sourced by Members of Merry-Jane.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        4.2. The Member may only use any portal section of the Application after
        successful registration with Merry-Jane. The Member’s Credentials will
        be needed to use and access the portal.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        4.3. Access may also be granted on-site at all physical locations of
        Merry-Jane on scrutiny of a completed and signed application form
        on-site.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        4.4. The Member hereby agrees that once the correct Credentials to the
        Member’s account have been entered, irrespective of whether the use of
        the Credentials is unauthorized, the Member will be liable for payment
        of such membership, save where the membership is cancelled by the Member
        in accordance with this Agreement.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        4.5. Merry-Jane reserves its right to terminate unconfirmed and/or
        inactive accounts. In addition, Merry-Jane reserves its sole and
        absolute discretion to refuse or to terminate all or part of its
        services to the Member for any reason whatsoever.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        4.6. Merry-Jane may in its sole discretion terminate, suspend and modify
        the Application, with or without notice to the Member. The Member hereby
        agrees that Merry-Jane will not be liable to the Member in the event
        that it chooses to suspend, modify or terminate the Application other
        than for processing any orders made by the Member prior to such time, to
        the extent possible.
      </div>
      <Title number={"5."} title={"DURATION"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        5.1. This Agreement shall commence on the date of signature of all
        parties hereto and shall continue for a period of 2 (two) years (Initial
        Period).
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        5.2. The Member shall have the option to renew this Agreement after the
        Initial Period for a further 1 (one) year period and on the same terms
        and conditions contained herein between the Parties. Should the Member
        elect to exercise the option as aforesaid, it shall do so by giving
        Merry-Jane2 (two) months’ written notice prior to expiry of the Initial
        Period.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        5.3. Upon expiry of the Initial Period referred to in clause 5.1, if the
        Member does not exercise the option to renew this Agreement as set out
        in clause 5.2, this Agreement shall automatically terminate.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        5.4. Upon expiry of this Agreement the Member will have 14 (fourteen)
        days within which to collect their seed/s and/or crop/s and/or
        harvest/s. The Member consents to the destruction of their seed/s and/or
        crop/s and/or harvest/s in the event that the such is not collected
        within 1 (one) month from the date of expiry of this Agreement.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        5.5. Either the Member or Merry-Jane may terminate this Agreement
        subject to the termination provisions contained in clause 15 and 16.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        5.6. Each party hereto confirms that they have read, understood and
        accepts the terms and conditions contained herein and in all and any
        attachment hereto.
      </div>
      <Title number={"6."} title={"FEES, DEDUCTIONS AND PAYMENTS"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        6.1. The Parties agree that the Member shall be liable to pay the
        Membership Fee in advance, as set out on the member`s indemnity form,
        which payment will commence on the Registration Date and thereafter on
        the same day of each subsequent month, or as agreed between Merry-Jane
        and the Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        6.2. All payments made by the Member to Merry-Jane shall be made without
        set-off and/or deduction.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        6.3. In due course as Merry-Jane develops, Members shall become entitled
        to allied and affiliate programs; such extra membership entitlements
        might require additional levies.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        6.4. All payments made by the Member to Merry-Jane shall be inclusive of
        VAT (if applicable) unless otherwise stipulated.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        6.5. The Membership Fee is subject to change from time to time with
        reasonable notice to the Member and Merry-Jane reserves the right to
        effect such change within its discretion.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        6.6. The Member shall pay the Membership Fee into Merry-Jane bank
        account, which details are set out on the membership form.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        6.7. Payment shall be made by the Member to Merry-Jane by direct bank
        deposit, instant electronic funds transfer (EFT), or credit card. Where
        a credit card is used, Merry-Jane may require additional information in
        order to authorize and/or verify the validity of payment. If such
        authorization is not received, the order will be cancelled.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        6.8. Any increase in Merry-Jane cost of supplying services caused by any
        level of governmental law, regulation, tax, or other burden imposed
        after the date of this Agreement on the ownership, storage, processing,
        production, distribution or use of the Cannabis covered by this
        Agreement will be added to the price under this Agreement after notice
        of such increase has been provided to the Member.
      </div>
      <Title
        number={"7."}
        title={
          "MEMBER, CLUB & PROFESSIONAL HORTICULTURAL SERVICES PROVIDER WARANTIES"
        }
      />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1. The Member:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1.1. The Member confirms that he/she understands the contents of and
        accepts the specifications of the membership model the Member has
        selected.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1.2. The Member agrees that it is and will remain the sole lawful
        owner of the seed/s, crop/s and harvest/s produced on their assigned
        patch throughout the membership period, and the rights contained herein
        may not be ceded or assigned to any third party, which does not exclude
        the Member’s right to share their crops with other members without the
        exchange of consideration. If the Member decides not to continue growing
        or elects to terminate this agreement then all product from his harvest
        will be disposed of and all plants will be destroyed. It is not
        permissible for the Member to remove any plant or crop once the
        agreement has been terminated.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1.3. The Member undertakes to pay the monthly membership fee for
        membership and the fee for growing. Failure to pay the monthly fees will
        result in a suspension of the services and the Member will be afforded
        14 (fourteen) days within which to rectify payment. Failure to rectify
        payment within 14 (fourteen) days will result in termination of the
        Membership and Lease. It is noteworthy that without the requisite lease
        the Member will be operating not in accordance with the current laws.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1.4. The Member agrees that any additional services or specific inputs
        not included in the selected membership model will be charged at an
        additional cost to the Member as quoted and agreed between the parties
        in terms of the specific additional fee structure.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1.5. The Member undertakes to personally obtain the seed/s of the
        desired crop from a supplier and Merry-Jane will assist to deliver the
        seed/s to the Professional Horticultural Services Provider member for
        cultivation.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1.6. The Member acknowledges that he/she has a limited visitation
        right to its Leased Blockchain and supervised visitation thereto must be
        pre-arranged with Merry-Jane The Member further undertakes to abide by
        the visitation safety and security regulations as set in Annexure B
        (“Code of Conduct”).
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1.7. If the Member does not understand this Agreement, it is its
        responsibility to ask Merry-Jane to explain before acceptance of the
        Agreement or continue using the Application.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.1.8. The Member hereby agrees to uphold the rules of Merry-Jane set
        out in Annexure B (“Code of Conduct”) and Annexure C (“Constitution”)
        attached hereto.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2. Merry-Jane warrants that it shall:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.1. at all times ensure that in choosing its products suppliers and
        manufacturers, the highest standards of quality and processing of the
        plant in all its formats raw, oil, distillates, edibles and all future
        formats will be adhered to ensure that the standards are of the highest
        quality;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.2. take all reasonable precautions to guard against any loss to the
        Member through the failure of Merry-Jane, its employees, partners,
        agents, contractors, representatives and/or associates to execute their
        respective commitments properly and on time and to carry out the
        Services as agreed in terms of the Information Sheet;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.3. ensure that the Services shall be carried out in accordance with
        the industry best practices in which the Services are to be performed;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.4. timeously advise the Member of any circumstances or events which
        may prevent or inhibit Merry-Jane in the carrying out of the Services;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.5. not action any work, beyond the scope of the Services, without
        obtaining the prior written approval of the Member;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.6. issue detailed and complete invoices and statements timeously,
        where applicable;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.7. carry out, conscientiously all such functions and duties as are
        reasonable upon the instruction of the Member;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.8. observe the standards and principles set out by Merry-Jane, from
        time to time, in the conduct of its business; and
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.2.9. abide by the current and future customs, rules, policies and
        regulations of the industry, including respecting membership holder’s
        right to privacy and complying with the provisions of the POPI Act which
        regulates the manner in which Merry-Jane collects, processes, stores,
        shares and destroys any personal information which you have provided us.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.3. The Professional Horticultural Services Provider:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.3.1. On and from the commencement date, the Professional Horticultural
        Services Provider will provide the cultivating expertise as per the
        Member’s preference.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.3.2. The Professional Horticultural Services Provider will not
        purchase the seed/s on behalf of the Member but will, upon request by
        the Member, offer advice on the type of seeds to be procured for the
        Member’s desired personal use.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.3.3. Upon delivery of the seed/s by the Member, the Professional
        Horticultural Services Provider will undertake to plant the seed/s and
        commence with the photographic and Grow Diary process of record keeping
        as required by Merry-Jane
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.3.4. The Professional Horticultural Services Provider acknowledges
        that the Member is and will remain the sole lawful owner of the seed/s,
        crop/s and harvest/s produced on the Member’s assigned patch throughout
        the membership period.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.3.5. The Professional Horticultural Services Provider acknowledges
        that the cultivation of the crop/s for the Member is strictly for the
        private and personal use of the Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.3.6. The Professional Horticultural Services Provider undertakes to
        supply the membership services promptly, diligently and in a
        professional manner, in accordance with the practices and high
        professional standards used in performing services similar to the
        Services herein.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        7.3.7. The Professional Horticultural Services Provider acknowledges
        that the Member has a limited visitation right to the Member’s Leased
        Blockchain and supervised visitation thereto must be pre-arranged with
        Merry-Jane
      </div>
      <Title number={"8."} title={"WAIVERS"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        8.1. Merry-Jane does not warrant any specific level of strength, potency
        or concentration in respect of the Cannabis.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        8.2. The Member hereby indemnifies Merry-Jane and its dispensers and
        practitioners and holds them harmless against any and all claims of
        whatsoever nature and howsoever arising in relation to the use,
        possession or transport of the Cannabis obtained by the Member, or any
        rights and obligations contained in this Agreement, specifically the
        provision by Merry-Jane of the Services, direct or indirectly, and
        whether such claim arose as a result of any negligent conduct on the
        part of Merry-Jane.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        8.3. Neither Party shall be liable for any consequential, indirect,
        general or punitive damages arising in terms of this Agreement.
      </div>
      <Title number={"9."} title={"RELATIONSHIP BETWEEN THE PARTIES"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        9.1. The Parties record that Merry-Jane shall fulfil its obligations in
        terms of this Agreement as an independent contractor, and not as a
        labour broker, employee or agent of the Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        9.2. Save as otherwise provided herein, neither Party shall be entitled
        to bind the other Party to any obligation of any nature whatsoever or to
        incur any liability on behalf of the other Party, whether in contract or
        otherwise.
      </div>
      <Title number={"10."} title={"LIABILITY"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        10.1. The use of the services provided by Merry-Jane is entirely at the
        Member’s own risk and the Member assumes full responsibility for any
        damage or loss resulting from the use of the Services.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        10.2. Merry-Jane cannot be held liable for any inaccurate information
        published on the Member’s Information Sheet and/or any incorrect costing
        displayed on the Member’s Information Sheet, save where such liability
        arises from the gross negligence or wilful misconduct of Merry-Jane, its
        employees, agents or authorised representatives.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        10.3. Merry-Jane shall not be liable for any direct, indirect,
        incidental, special or consequential loss or damages which might arise
        from the Member’s use of, or reliance upon, the Services contemplated in
        this Agreement.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        10.4. The Member hereby indemnifies Merry-Jane, its employees, agents
        and authorised representatives and holds any and all of them harmless
        against any claim, charge or criminal prosecution which may arise as a
        result of the Member utilising any Cannabis to deal or trade in or
        distribute or acquire any illegal substance or product, and Merry-Jane
        shall not be in any way responsible for any legal action or criminal
        prosecution which a Member may face as a result of any misuse of the
        Services or Cannabis.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        10.5. The Member’s indemnification of Merry-Jane shall cover all legal
        actions, suits, proceedings, claims, demands, costs and expenses
        whatsoever, which may be brought against Merry-Jane or incurred or
        become due and payable by Merry-Jane arising from or in respect of
        Services rendered in respect of the Member’s Cannabis, including but not
        limited to, any claims arising out of the Services provided by MerryJane
        to the Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        10.6. Whilst Merry-Jane shall do all things reasonably necessary to
        protect the Member’s rights to privacy, including compliance with all
        applicable local laws, Merry-Jane cannot guarantee or accept any
        liability whatsoever for unauthorised or unlawful disclosure of the
        Member’s personal information, whilst in Merry-Jane possession, made by
        third parties who are not subject to its control, unless such disclosure
        is as a result of the gross negligence or wilful misconduct of
        Merry-Jane, its employees, agents or authorised representatives.
      </div>
      <Title number={"11."} title={"SHIPPING AND DELIVERY"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        11.1. Merry-Jane will offer delivery of the Cannabis by courier directly
        to the Member’s home or office, anywhere in South Africa. Delivery
        details including the delivery fees of the order will be supplied to the
        Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        11.2. Cannabis delivery fees are not included in the Membership Fee.
        Delivery fees will be charged separately and in accordance with the
        courier Club’s service fee. The courier Club will be responsible for
        delivery of the Cannabis to the Member’s specified geographical area.
        The Member’s geographical area will be selected by the Member on the
        Application.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        11.3. To the extent that the Application accepts the Member’s order,
        Merry-Jane will notify the Member of the date of the expected delivery,
        which expected delivery may be agreed upon as between Merry-Jane and the
        Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        11.4. Merry-Jane shall notify the Member in the event that Merry-Jane is
        unable to deliver the Cannabis during the Delivery Period.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        11.5. In the event that the Member’s Cannabis cannot be delivered in any
        given month for any reason whatsoever, Merry-Jane shall communicate this
        to the Member as soon as reasonably practical. Where the order was
        cancelled as a result of events within Merry-Jane control, Merry-Jane
        shall reimburse the Member all amounts paid by the Member for the month
        in which no delivery could take place, subject to compliance with any
        applicable cancellation policies and procedures applicable from time to
        time. To the extent that delivery of any Cannabis became impossible for
        any reason beyond the control of Merry-Jane, Merry-Jane shall not be
        obliged to refund the Member the Membership Fee for the month in
        question but may elect to do so in its sole and absolute discretion.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        11.6. Prior to delivery of the Cannabis, the Member will receive an
        email notification confirming delivery at an address nominated by the
        Member. Should the Member fail to respond to the email notification,
        Merry-Jane will assume that no change in address has been made and
        Merry-Jane will deliver the Cannabis to the address nominated by the
        Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        11.7. Merry-Jane obligation to deliver the Cannabis to the Member is
        fulfilled upon delivery of the Cannabis to the physical address
        nominated by the Member for delivery. Merry-Jane is not responsible for
        any loss or unauthorized use of the Cannabis after the Cannabis has been
        delivered to the physical address nominated by the Member or the Member
        has taken receipt of their cannabis or other Member’s cannabis shared
        with them.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        11.8. Should the Member provide Merry-Jane with incorrect address
        details for the delivery of the Cannabis, the Member will be liable for
        a double fee payable to the courier service which will be debited from
        the Member’s account.
      </div>
      <Title number={"12."} title={"RETURNS AND REFUNDS"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        12.1. In the event that Merry-Jane accidentally provides the wrong
        Cannabis to the Member, or if the Cannabis is not as described on the
        Information Sheet, the Member shall be entitled to notify Merry-Jane and
        Merry-Jane shall use its Best Endeavours to correct the error, based on
        the Member’s complaint, at no additional charge.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        12.2. Merry-Jane shall take all reasonable steps to ensure that the
        Cannabis is delivered by Merry-Jane without any defects and in
        acceptable packaging.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        12.3. Should the Member receive damaged, unusable or poor-quality
        Cannabis, the Member shall notify Merry-Jane within a reasonable period
        after he/she becomes aware of the defect or poor quality, but in any
        event within 3 (three) days after delivery of the Cannabis and
        Merry-Jane shall use its Best Endeavours to remedy the complaint.
        Importantly, such defects shall relate only to the Services rendered in
        respect of the Cannabis, and not the Cannabis itself, which is the
        Member’s property.
      </div>
      <Title number={"13."} title={"GENERAL"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        13.1. This Agreement is concluded solely between Merry-Jane and the
        Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        13.2. The Member is not permitted to cede, sell, transfer, donate or
        alienate its membership in any way or form to another person, or use the
        same action in order to sell, distribute or deal in the cannabis in any
        way or form.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        13.3. To the extent that a Member cancels its membership with Merry-Jane
        at any time, the Member shall remain liable for all Membership Fees up
        until cancellation of the order, as well as all reasonable costs
        incurred by Merry-Jane in providing the Services which it has yet to
        recover.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        13.4. To the extent that that Merry-Jane is unable to provide the
        requisite volume of Cannabis on behalf of the Member as per the
        Information Sheet for any reason whatsoever, Merry-Jane may, in its
        discretion, supplement the shortfall of Cannabis from its own stocks.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        13.5. Merry-Jane may accept an order depending on the correctness and
        accuracy of the information provided by the Member, and upon receipt of
        payment and/or payment authorisation by Merry-Jane of the relevant
        Membership Fee.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        13.6. Merry-Jane reserves its right to amend, update, change or replace
        any part of this Agreement and provisions contained herein at its sole
        and absolute discretion. Any amendment, update, change or replacement of
        this Agreement and provisions shall be delivered to the Member by way of
        email, and the continued use of and access of the service following any
        amendment, update, change or replacement of provisions shall constitute
        acceptance of same.
      </div>
      <Title number={"14."} title={"CONFIDENTIALITY"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        14.1. The Professional Horticultural Services Provider, subject to the
        Confidentiality and Intellectual Property provisions contained in
        Annexure B (“Code of Conduct”), undertakes to keep the identity of the
        Member, the location of the Member’s Leased Blockchain and the contents
        of the Grow Diary confidential throughout the period of membership and
        indefinitely thereafter.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        14.2. The Professional Horticultural Services Provider undertakes to not
        approach the Member directly to offer the services of the Professional
        Horticultural Services Provider for the Member’s personal use not
        covered by the provisions of this Agreement, thereby circumventing
        Merry-Jane In the event that the Professional Horticultural Services
        Provider circumvents THE Merry-Jane, the Professional Horticultural
        Services Provider will be liable for liquidated damages as set at R 100
        000.00 (One Hundred Thousand Rand) incurred by Merry-Jane as
        professionally quantified, including but not limited to loss of income,
        reputational damage and legal costs.
      </div>
      <Title number={"15."} title={"TERMINATION"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        15.1. Merry-Jane will use reasonable endeavours to maintain the
        availability of the Application, except during scheduled maintenance
        periods, and is entitled to discontinue providing the Application
        services or any part thereof with or without notice to the Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        15.2. Merry-Jane may, in its sole and absolute discretion, terminate the
        Agreement if the Member fails to comply with any of the provisions
        contained herein.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        15.3. The Member hereby agrees that Merry-Jane will not be liable to the
        Member in the event that it chooses to suspend, modify or terminate the
        Application other than for processing any transactions entered into by
        the Member prior to such time, to the extent possible.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        15.4. Should the Member fail to comply with its obligations under this
        Agreement, including any incident involving payment of the Membership
        Fee, this may lead to a suspension and/or termination of the Agreement
        without any prejudice to any claim for damages or otherwise which
        Merry-Jane may have against the Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        15.5. Merry-Jane is entitled, for purposes of preventing suspected fraud
        and/or where it suspects that the Member has created multiple profiles
        to take advantage of a promotion intended by Merry-Jane to be used
        once-off by the Member, to blacklist the Member, refuse to accept or
        process payment on any order, and/or to cancel any order concluded
        between the Member and Merry-Jane, in whole or in part, on notice to the
        Member.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        15.6. Merry-Jane shall only be liable to refund the Member money already
        paid (at its sole and absolute discretion) and accepts no other
        liability which may arise as a result of such blacklisting and/or
        refusal to process any transaction.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        15.7. The Member may, at any time, choose to terminate use of the
        Application, with or without notice to Merry-Jane.
      </div>
      <Title number={"16."} title={"BREACH"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        16.1. Should either Party:
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        16.1.1. fail to meet any of its payment obligations in terms of this
        Agreement, as and when such payment becomes due;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        16.1.2. terminate or attempt to terminate this Agreement prior to the
        discharge of all of the Member’s obligations in terms of this Agreement;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        16.1.3. commit any fraudulent or wilful misconduct in the performance of
        this Agreement;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        16.1.4. have made any incorrect, false or misleading representation,
        statement or warranty in this Agreement, or in any other document
        provided or required in connection with this Agreement, alternatively
        any representation, statement or warranty becomes incorrect, false or
        misleading during the term of this Agreement and fail immediately to
        advise the other Party of such change in writing; or
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        16.1.5. commit any breach of any other provision of this Agreement and
        fail to remedy same within 7 (seven) days of receipt of notice from
        Merry-Jane calling on the Member to remedy;
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        16.1.6. same shall constitute a material breach of this Agreement and,
        without prejudice to any other rights which the non-defaulting Party may
        have in law, shall entitle the non-defaulting Party, without providing
        any further notice to the defaulting Party, to terminate this Agreement
        and recover any and all damages which it may have suffered as a result
        of such termination.
      </div>
      <Title number={"17."} title={"VARIATION, INDULGENCE AND WAIVER"} />
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        17.1. This agreement constitutes the whole of the agreement between the
        parties relating to the subject matter thereof, and no amendment,
        alteration, addition, variation or consensual cancellation will be of
        any force or effect unless reduced to writing and signed by the parties.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        17.2. This agreement, along with all annexures attached hereto, contains
        all the terms and conditions agreed to by the parties. The parties
        acknowledge that there are no understandings, representations, other
        terms or conditions, whether oral or written, and whether express or
        implied which induced either party to this agreement to entering into
        the agreement, other than those set out herein. It is hereby further
        specifically recorded that the Member is not bound by any warranties,
        representations, undertakings or the like, whether express or implied,
        with regard to the membership, membership model, the Professional
        Horticultural Services Provider service or any other service provided
        herein to the Member, save those set out in this agreement and the
        annexes hereto. The Member acknowledges that it is fully acquainted with
        all of the affairs of the business.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        17.3. The Member hereby waives any right of rectification in regard to
        this agreement as well as reliance on the benefits of the contra
        preferentum rule, the scope and meaning of which the Member hereby
        acknowledge to be fully acquainted with.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        17.4. No act of relaxation on the part of the parties in regard to the
        carrying out of any of the obligations in terms of this agreement shall
        prejudice or be deemed to be a waiver of any of the rights in terms
        hereof, and no waiver of any of the terms and conditions of this
        agreement will be binding for any purpose unless expressed in writing
        and signed by the party giving the same, and any such waiver will be
        effective only in the specific instance and for the purpose given. No
        failure or delay on the part of either party in exercising any right,
        power or privilege will operate as a waiver, nor will any single or
        partial exercise of any right, power or privilege preclude any other or
        further exercise thereof or the exercise of any other right, power or
        privilege.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        17.5. No concession or any other indulgence which may be granted by
        Merry-Jane to the Member whether in respect of time for payment or
        otherwise in regard to the terms and conditions of this Agreement shall
        be deemed to be a waiver or estoppel of or affect, prejudice or derogate
        from the rights of Merry-Jane under this Agreement.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 10,
          textAlign: "left",
          fontSize: 14,
        }}
      >
        17.6. The liability of the Member/s (if more than one) to Merry-Jane for
        payment of Membership fees and/or costs of cultivation and fulfilment of
        the other obligations herein imposed the Member, shall be joint and
        several.
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 20,
          textAlign: "left",
          // fontWeight: "bold",
          fontSize: 14,
          marginBottom: 20,
        }}
      >
        By the Member’s signature below, he/she/they warrant that he/she/they
        have fully read and understood the contents hereof, acknowledge that
        they intend to hereby become a Member of Merry-Jane Private Members
        Club, on the terms and conditions hereof and specifically accept the
        inherent risk of participating in a private members club for the
        cultivation, possession, storage, transport, use and sharing of
        cannabis, without the exchange of consideration for cannabis, belonging
        to the Member.
      </div>
      <div>
        <TextInputField
          mini={true}
          invert={true}
          labelTitle={"SIGNED AT"}
          readOnly={isSigned ? true : false}
          value={isSigned ? signedAtAlready : signedAt}
          onChange={(e) => setSignedAt(e.target.value)}
        />
      </div>
      <div>
        <TextInputField
          mini={true}
          invert={true}
          labelTitle={"DATE"}
          readOnly={isSigned ? true : false}
          value={isSigned ? dateCreated : new Date().toDateString()}
        />
      </div>
      <div style={{ marginBottom: 20 }}>
        <TextInputField
          mini={true}
          invert={true}
          labelTitle={"MEMBER FULL NAME"}
          readOnly={true}
          value={userName}
        />
      </div>
      {isSigned ? null : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <BaseButtons
            disable={signedAt === "" ? true : false}
            label={"ACCEPT AGGREMENT"}
            width={"100%"}
            marginTopFalse={true}
            size={"80%"}
            onClick={onAcceptClick}
          />
        </div>
      )}
    </div>
  );
};

export default SocialMemberTerms;
